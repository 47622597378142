/*
import React from 'react';

import './Button.scss';

const button = (props)  => {

  return <button className={"Button "+props.customClass} onClick={props.click}>{props.children}</button>;

}

export default button;

*/

import * as React from "react";
import Button from "@mui/material/Button";

import "./Button.scss";

export default function TextButtons({
  disabled,
  click,
  children,
  customClass,
  variant,
  margin,
  fullWidth = false,
  key,
  style,
  height,
  width,
}) {
  return (
    <Button
      height={height}
      width={width}
      fullWidth={fullWidth}
      style={{ minHeight: "5rem", ...style }}
      className={customClass}
      disabled={disabled}
      onClick={click}
      variant={variant}
      key={key}
      margin={margin}
    >
      {children}
    </Button>
  );
}
