import React, {Component} from 'react';
import axios from 'axios';

import LevelSection from './LevelSection/LevelSection';
import Doty from '../../../../components/UI/Doty/Doty';

import './Difficulty.scss';

class Difficulty extends Component {
  state = {
    sections: null
  }


  componentDidMount () {
    console.log(this.props);
    let data = {
      id:this.props.idLevel
    }
    axios.post("/getSectionsById",data)
    .then(response => {
      console.log(response.data.sections);
      if(response.data.sections.length > 0){
        this.setState({sections:response.data.sections});
      }

    })
    .catch(error => {
      console.log(error);
    })

  }

  render(){
    let sections = null;
    if(this.state.sections){
      console.log(this.state.sections);
        sections = this.state.sections.map((item, index) => {
          console.log(item);
          return <LevelSection id={item.id} key={item.id} name={item.name} />
        })
    }
    console.log(this.props);
    return (
      <div className="Difficulty">
        <div  className="Difficulty__title">
          <Doty size="Difficulty__title-img" pose={this.props.pose} />
          <span className="Difficulty__title-text">{this.props.name+(Number(this.props.enabled)===0?" (coming soon...)":"")}</span>

        </div>
        {sections}
      </div>
    );
  }

}

export default Difficulty;
