import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
} from "@mui/material";
import { Add, Check, Edit } from "@mui/icons-material";
import Input from "../../../../../components/UI/Input/Input";
import H from "../../../../../components/UI/H/H";
import { BASE_URL_IMAGES, BASE_URL_SOUNDS } from "../../../../../constants";
import AlertDialog from "../../../../../components/UI/Dialog/Dialog";
import Sound from "../../../../../hoc/Sound/Sound";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewEditFormWord({
  idLevel,
  wordEdit = "",
  wordImageEdit = "",
  wordSoundEdit = "",
  edit,
  show,
  cancel,
  levelName,
  onOk,
}) {
  const [word, setWord] = useState();
  const [wordImage, setWordImage] = useState();
  const [actualWordImageSrc, setActualWordImageSrc] = useState();
  const [wordSound, setWordSound] = useState();
  const [actualWordSoundSrc, setActualWordSoundSrc] = useState();
  const [dialog, setDialog] = useState({});

  useEffect(() => {
    console.log("wordEdit", wordEdit);
    if (wordEdit !== "") {
      setWord(wordEdit);
    }
    if (wordImageEdit !== "") {
      setWordImage(wordImageEdit);
    }
    if (wordSoundEdit !== "") {
      setWordSound(wordSoundEdit);
    }
  }, [wordEdit, wordImageEdit, wordSoundEdit]);

  useEffect(() => {
    if (!edit) {
      console.log("CLEARS");
      setWord("");
      setWordImage("");
      setWordSound("");
    }
  }, [edit]);

  const validateWord = (edit) => {
    let message = "";
    if (word === "") {
      message = "Please fill the word!";
    } else if (wordSound === null) {
      message = "Please upload a word audio!";
    }else if (wordImage === null && !edit) {
      message = "Please upload a word audio!";
    }
    if (message !== "") {
      setDialog({
        text: message,
        type: "warning",
        accept: () => {
          setDialog({ show: false });
        },
        accept_text: "Ok!",
        show: true,
      });
      return false;
    } else {
      return true;
    }
  };

  const editWord = () => {
    const validWord = validateWord(true);

    if (validWord) {
      let formData = new FormData();
      formData.append("word", word);

      if (wordImage) {
        formData.append("word_image", wordImage);
      }
      if (wordSound) {
        formData.append("word_sound", wordSound);
      }

      formData.append("path", String(levelName).toLowerCase());
      formData.append("name", word.toLowerCase());

      axios({
        url: "/word",
        method: "put",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          onOk();
          setDialog({
            text: "Sentence edited!",
            type: "success",
            accept: () => {
              cancel();
              setDialog({ show: false });
            },
            accept_text: "Great!",
            show: true,
          });

          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          setDialog({
            text: "Something went wrong...! Please try again!",
            type: "error",
            accept: () => {
              setDialog({ show: false });
            },
            accept_text: "Ok :(",
            show: true,
            cancel: () => {
              setDialog({ show: false });
            },
          });
        });
    }
  };

  const submitWord = () => {
    const validWord = validateWord(false);

    if (validWord) {
      let formData = new FormData();
      formData.append("word", word);
      formData.append("word_sound", wordSound);
      if (wordImage) {
        formData.append("word_image", wordImage);
      }
      formData.append("path", String(levelName).toLowerCase());
      formData.append("name", word.toLowerCase());
      formData.append("id_level", idLevel);

      axios({
        url: "/word",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          onOk();
          setDialog({
            text: "Word created!",
            type: "success",
            accept: () => {
              cancel();
              setDialog({ show: false });
            },
            accept_text: "Great!",
            show: true,
          });

          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          setDialog({
            text: "Something went wrong...! Please try again!",
            type: "error",
            accept: () => {
              setDialog({ show: false });
            },
            accept_text: "Ok :(",
            show: true,
            cancel: () => {
              setDialog({ show: false });
            },
          });
        });
    }
  };

  console.log("renders", show);
  return (
    <>
      <Dialog
        open={show}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
        onClose={cancel}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          {edit ? (
            <>
              Edit Word <Edit />
            </>
          ) : (
            <>
              New Word <Add />
            </>
          )}
        </DialogTitle>
        <DialogContent sx={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "30rem" }}>
            <Input
              fullWidth
              placeHolder="Word"
              change={(event) => {
                setWord(event.target.value);
              }}
              value={word}
            />
            <H type="5">Word sound</H>
            <Card variant="outlined" sx={{ marginBottom: "2rem" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
              >
                <Button variant="contained" component="label">
                  {wordSound ? (
                    <>
                      Uploaded <Check />
                    </>
                  ) : (
                    "Upload"
                  )}
                  <input
                    hidden
                    accept="audio/*"
                    type="file"
                    onChange={(event) => {
                      setWordSound(event.target.files[0]);
                    }}
                  />
                </Button>
                <Sound
                  icon
                  size
                  position="rigth"
                  src={actualWordSoundSrc ? actualWordSoundSrc : wordSound}
                >
                  <img
                    style={{ width: "6rem", height: "6rem" }}
                    src={BASE_URL_IMAGES + "/words/others/bocina.png"}
                    alt={"speaker"}
                  />
                </Sound>
              </Stack>
            </Card>
            <H type="5">Word image</H>
            <Card variant="outlined">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
              >
                <Button variant="contained" component="label">
                  {wordImage ? (
                    <>
                      Uploaded <Check />
                    </>
                  ) : (
                    "Upload"
                  )}
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      setWordImage(event.target.files[0]);
                      setActualWordImageSrc(event.target.files[0]);
                    }}
                  />
                </Button>
                <img
                  style={{ width: "6rem", height: "6rem" }}
                  src={
                    actualWordImageSrc
                      ? actualWordImageSrc
                      : BASE_URL_IMAGES +
                        "/words/" +
                        (wordImage ? wordImage : "others/bocina.png")
                  }
                  alt={"speaker"}
                />
              </Stack>
            </Card>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              edit ? editWord() : submitWord();
            }}
          >
            {edit ? "Edit" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog {...dialog} />
    </>
  );
}
