import React, { useState, useContext } from "react";

const navContext = React.createContext();
const navChangeContext = React.createContext();

export function useNavContext() {
  return useContext(navContext);
}

export function useNavChangeContext() {
  return useContext(navChangeContext);
}

export function NavProvider({ children }) {
  const [nav, setNav] = useState(false);
 
  
  return (
    <navContext.Provider value={nav}>
      <navChangeContext.Provider value={setNav}>
        {children}
      </navChangeContext.Provider>
    </navContext.Provider>
  );
}
