import React, { useState } from "react";

import WordImg from "../../../../../../components/UI/WordImg/WordImg";
import Button from "../../../../../../components/UI/Button/Button";
import Modal from "../../../../../../components/UI/Modal/Modal";
import { Box, Card, Stack, Typography } from "@mui/material";
import Lock from "../../../../../../assets/images/Lock_icon.png";

import "./LevelWord.scss";
import LoadBar from "../../../../../../components/UI/LoadBar/LoadBar";
import { CheckCircle } from "@mui/icons-material";
const profile = localStorage.getItem("profile");
export default function LevelWord({
  on_construction,
  id,
  color,
  name,
  src,
  animation,
  available,
  levels_left,
  progress,
}) {
  if (profile === "1") {
    //available = true;
  }
  const [clicked, setClicked] = useState(false);

  const toggleHandler = () => {
    if (on_construction !== 1 || profile === "1") {
      setClicked(!clicked);
    }
  };

  const goToPracticeHandler = () => {
    window.location.replace("/practice?id=" + id);
  };
  const goToSpellingHandler = () => {
    window.location.replace("/spelling?id=" + id);
  };

  let modal = null;
  if (clicked) {
    modal = (
      <Modal
        customClass={" LevelWord__modal animated zoomIn " + color}
        click={toggleHandler}
      >
        <span className="LevelWord__modal__title">{name}</span>
        <WordImg size="LevelWord__modal__img" src={src} alt="" />
        <Stack gap={1} width={200}>
          <Button
            fullWidth
            variant="contained"
            customClass="primary"
            click={goToPracticeHandler}
          >
            Practice
          </Button>
          <Button
            fullWidth
            variant="contained"
            customClass="primary"
            click={goToSpellingHandler}
          >
            Spelling
          </Button>
        </Stack>
      </Modal>
    );
  }

  return (
    <>
      {modal}
      <div>
        <div
          className="LevelWord "
          onClick={() => {
            available && toggleHandler();
          }}
        >
          <Box
            style={{
              borderRadius: "2rem",
              position: "relative",
              overflow: "hidden",
            }}
            className={
              "LevelWord__image-container " + (available ? color : "disabled")
            }
          >
            {!available && (
              <Stack
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backdropFilter: " blur(8px)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(204,204,204,.1)",
                  gap: "2",
                }}
              >
                <img src={Lock} alt="Locked" style={{ width: "4rem" }} />
                <Typography>{levels_left}</Typography>
              </Stack>
            )}
            <WordImg size="LevelWord__img" src={src} alt="" />
          </Box>
          <Stack
            color={progress > 99 ? "green" : "inherit"}
            alignItems={"center"}
          >
            <Typography variant="h5">{name}</Typography>
            {progress > 99 && <CheckCircle fontSize="large" />}
          </Stack>

          {available && progress < 100 && (
            <Box width={140}>
              <LoadBar progress={progress} />
            </Box>
          )}
          {/*<div className="LevelWord__loader"></div>*/}
        </div>
      </div>
    </>
  );
}
