import React, { Component, useEffect, useState } from "react";
import axios from "axios";

import H from "../../components/UI/H/H";
import GoBack from "../../components/UI/GoBack/GoBack";
import WordImg from "../../components/UI/WordImg/WordImg";
import Toolkit from "../../components/UI/Toolkit/Toolkit";
import Spinner from "../../components/UI/Spinner/Spinner";
import Player from "../../components/UI/Player/Player";

import "./Reading.scss";
import { BASE_URL_SOUNDS } from "../../constants";
import { Container, Typography, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { readingByIdService } from "../../services/reading.service";
import { useTheme } from "@emotion/react";

export default function Reading() {
  const [words, setWords] = useState(null);
  const [title, setTitle] = useState(null);
  const [src, setSrc] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const params = useParams();
  const id_reading = params.id;
  useEffect(() => {
    const getReading = async () => {
      const reading = await readingByIdService(id_reading);
      console.log("reading", reading);
      setWords(reading.text);
      setTitle(reading.title);
      setSrc(reading.src);
    };

    if (id_reading > 0) {
      getReading();
    } else {
      return;
    }
  }, []);

  let content = <Spinner />;
  if (words && title !== "NOK") {
    const paragraph = words
    content = (
      <div className="Reading__content">
        <Typography  variant="h2" color={"primary"}>
          {title}
        </Typography>
        <Player player={new Audio(BASE_URL_SOUNDS + "/" + src)} src={src} />
        <div className="Reading__paragraph">{paragraph}</div>
      </div>
    );
  } else {
    content = (
      <div className="Reading__loading">
        <span>No readings yet! Please come back later!</span>
        <WordImg src="under_construction.png" size="big" />
      </div>
    );
  }

  return <Container sx={{position:"relative", paddingTop:isMobile ? "4rem": ""}}><GoBack to="/levels" />{content}</Container>;
}
