import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import Doty from "../Doty/Doty";
import { Warning } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  text,
  title = "",
  accept = null,
  cancel = null,
  accept_text = "",
  cancel_text = "",
  type = "",
  show,
  content,
}) {
  const newStyle = { display: "flex", alignItems: "center" };
  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={cancel}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {title !== "" ? (
          title
        ) : type === "success" ? (
          "Great!"
        ) : type === "warning" ? (
          <span style={newStyle}>
            Warning! &nbsp; <Warning />
          </span>
        ) : type === "error" ? (
          "Oh no!"
        ) : (
          ""
        )}
      </DialogTitle>
      <DialogContent sx={{ display: "flex", alignItems: "center" }}>
        {type === "success" && <Doty pose="02" size="smaller" />}
        {type === "warning" && <Doty pose="07" size="smaller" />}
        {type === "error" && <Doty pose="05" size="smaller" />}
        {type === "info" && <Doty pose="17" size="smaller" />}
        <DialogContentText id="alert-dialog-slide-description">
          {text}
        </DialogContentText>

        {content}
      </DialogContent>
      <DialogActions>
        {cancel_text !== "" && <Button onClick={cancel}>{cancel_text}</Button>}
        {accept_text !== "" && accept && (
          <Button onClick={accept}>{accept_text}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
