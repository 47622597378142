import { Box } from "@mui/material";
import { useState } from "react";
import { newReadingService } from "../../../services/reading.service";
import Dialog from "../../../components/UI/Dialog/Dialog";
import AdminReadingsEditor from "./AdminReadingsEditor";

export default function AdminReadingsEdit() {
  const [dialog, setDialog] = useState({});

  const closeDialogHandler = () => {
    setDialog({ show: false });
  };
  const saveReading = (title, text, readingAudio) => {
    let error = false;
    let error_msg = "";
    if (title === "") {
      error = true;
      error_msg += " title";
    }
    if (text === "") {
      error = true;
      error_msg += " body";
    }
    if (readingAudio === undefined || readingAudio === null) {
      error = true;
      error_msg += " audio";
    }
    if (error) {
      error_msg = error_msg.trim().split(" ").join(", ") + " is missing!";
      setDialog({
        text: error_msg,
        type: "warning",
        accept: () => {
          closeDialogHandler();
        },
        accept_text: "Ok",
        show: true,
      });
      return;
    }

    const reading_service = newReadingService(text, title, readingAudio);
    console.log("reading_service", reading_service);
    if (reading_service) {
      setDialog({
        text: "Reading created!",
        type: "success",
        accept: () => {
          window.location.reload();
        },
        accept_text: "Great!",
        show: true,
      });
    } else {
      setDialog({
        text: "An error ocurred while creating this reading",
        type: "error",
        accept: () => {
          closeDialogHandler();
        },
        accept_text: "Ok",
        show: true,
      });
    }
  };

  return (
    <>
      <AdminReadingsEditor
        reading={{ title: "", text: "" }}
        saveReading={saveReading}
      />
      <Dialog {...dialog} />
    </>
  );
}
