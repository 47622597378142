import { useSearchParams } from "react-router-dom";
import axios from "axios";
import md5 from "md5";
import background from "../../assets/background.png";

import H from "../UI/H/H";

import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import Doty from "../UI/Doty/Doty";
import Spinner from "../UI/Spinner/Spinner";

import Logo from "../../assets/images/logos/DOTS_LOGO.png";

import "./Forgot.scss";
import { useEffect, useState } from "react";
import { Card } from "@mui/material";

const Forgot = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [place, setPlace] = useState("");
  const [idUser, setIdUser] = useState(0);
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const code = searchParams.get("code") ? searchParams.get("code") : "";

  useEffect(() => {
    console.log(code);
    let date = new Date();
    var month = date.getMonth() + 1; //months from 1-12
    var day = date.getDate();
    var year = date.getFullYear();
    var minutes = date.getMinutes();
    var hours = date.getHours();
    var seconds = date.getSeconds();
    date =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
    let data = {
      code: code,
      date: date,
    };
    console.log("here1");
    if (code) {
      axios
        .post("/checkCode", data)
        .then((response) => {
          console.log("here2", response);
          if (response.data.code === "OK") {
            setPlace("change");
            setIdUser(response.data.idUser);
          } else {
            setPlace("wrong");
          }
        })
        .catch((error) => {
          console.log("catch");
          setPlace("wrong");
        });
    } else {
      setPlace("email");
    }
  }, []);

  const sendEmailHandler = () => {
    console.log("HERE");
    if (email === "") {
    } else {
      let date = new Date();
      console.log(date);
      var month = date.getMonth() + 1; //months from 1-12
      var day = date.getDate();
      var year = date.getFullYear();
      var minutes = date.getMinutes();
      var hours = date.getHours();
      var seconds = date.getSeconds();
      date =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      let data = {
        email: email,
        date: date,
      };
      console.log(data);
      axios
        .post("/sendCodeMail", data)
        .then((response) => {
          console.log(response);
          response = response.data;
          if (response.code === "OK") {
            setPlace("email_sent");
          } else {
            setPlace("wrong");
            setMsg(response.msg);
          }
        })
        .catch((error) => {
          console.log("WRONG");
          setPlace("wrong");
        });
    }
  };

  const goToLoginHandler = () => {
    window.location.replace("/login");
  };
  const tryAgainHandler = () => {
    window.location.replace("/forgot");
    setPlace("email");
  };

  const changePassHandler = () => {
    if (pass !== pass2 || pass === "") {
      alert("pass not valid")
    } else {
      let date = new Date();
      console.log(date);
      var month = date.getMonth() + 1; //months from 1-12
      var day = date.getDate();
      var year = date.getFullYear();
      var minutes = date.getMinutes();
      var hours = date.getHours();
      var seconds = date.getSeconds();
      date =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      const passEncripted = md5(pass);
      let data = {
        pass: passEncripted,
        date: date,
        id: idUser,
      };
      console.log(data);
      axios
        .post("/changePassForgot", data)
        .then((response) => {
          console.log(response);
          response = response.data;
          if (response.code === "OK") {
            setPlace("success");
          } else {
            setPlace("wrong");
            setMsg(response.msg);
          }
        })
        .catch((error) => {
          console.log("WRONG");
          setPlace("wrong");
          setMsg("something went wrong");
        });
    }
  };

  let content = "";
  console.log("place", place);
  if (place === "wrong") {
    content = (
      <>
        <Doty pose="05" />
        <span>{msg}</span>
        <Button click={tryAgainHandler}>TRY AGAIN</Button>
      </>
    );
  } else if (place === "email") {
    console.log("EMAIL");
    content = (
      <>
        <Doty pose="03" />
        <br />
        <H>Please tell us your account email</H>
        <Input
          fullWidth
          placeHolder="Email"
          type="email"
          value={email}
          change={(event) => {
            setEmail(event.target.value);
          }}
        />
        <Button fullWidth variant="contained" click={sendEmailHandler}>Send</Button>
        <br/>
        <Button fullWidth variant="outlined"  click={goToLoginHandler}>GO TO LOGIN</Button>
      </>
    );
  } else if (place === "change") {
    content = (
      <>
        <Doty pose="07" />
        <br/>
        <H>Please write down your new password</H>
        <Input
          fullWidth
          placeHolder="Password"
          type="password"
          value={pass}
          noComplete
          change={(event) => {
            setPass(event.target.value);
          }}
        />
        <Input
        fullWidth
          placeHolder="Confirm password"
          type="password"
          value={pass2}
          noComplete
          change={(event) => {
            setPass2(event.target.value);
          }}
        />
        <Button fullWidth variant="contained" click={changePassHandler}>Send</Button>
      </>
    );
  } else if (place === "success") {
    content = (
      <>
        <Doty pose="02" />
        <br/>
        <H>You successfully changed you password</H>
        <br/>
        <Button variant="contained" click={goToLoginHandler}>GO TO LOGIN</Button>
      </>
    );
  } else if (place === "email_sent") {
    content = (
      <>
        <Doty pose="02" />
        <br/>
        <H>We have sent you an email, please follow the instructions</H>
        <br/>
        <Button variant="contained" click={goToLoginHandler}>OK!</Button>
      </>
    );
  } else {
    content = <Spinner />;
  }
  return <div style={{ backgroundImage: `url(${background})`, width: "100vw", height: "100vh" }}><Card className="Forgot">{content}</Card></div>;
};

export default Forgot;
