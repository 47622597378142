import React from 'react';

import BackDrop from '../BackDrop/BackDrop';

import './Modal.scss';

const modal = (props) => {
  return (
    <>
      <div  className={"Modal "+props.customClass}>{props.children}</div>
      <BackDrop click={props.click} />
    </>
  )
}

export default modal;
