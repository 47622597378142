import React, { Component } from 'react';


import './Doty.scss';

class doty extends Component {
  state={
    img:null,
    pose:null,
    size:null
  }
  componentDidMount () {
    let imageName = 'DOTTY-POSES-'+this.props.pose;
    import(`../../../assets/images/Doty/${imageName}.png`).then( src => {

      this.setState({img:src.default,pose:this.props.pose,size:this.props.size})
    })
  }

  shouldComponentUpdate(nextProps,nextState) {
    return (this.state.pose !== nextProps.pose || this.state.size !== nextProps.size)
  }
  componentDidUpdate () {
    //console.log("DID UPDATE");
    if(this.props.pose !== this.state.pose){
      let imageName = 'DOTTY-POSES-'+this.props.pose;
      import(`../../../assets/images/Doty/${imageName}.png`).then( src => {

        this.setState({img:src.default,pose:this.props.pose,size:this.props.size})
      })
    }



  }
  render(){
    let styleClass= ["Doty"];
    if(this.props.size){
      styleClass.push(this.props.size);
    }
    if(this.props.customClass){
      styleClass.push(this.props.customClass);
    }
    return (<img className={styleClass.join(' ')} src={this.state.img} alt=""/>);
  }

}

export default doty;
