/*import React from 'react';

import './Input.scss'

const input = (props) => {


  return (
    <div className="Input__container">
      <input className={"Input "+props.customClass} autoComplete={props.noComplete?"new-password":""} type={props.type} onChange={props.change} placeholder={props.placeHolder} value={props.value?props.value:''} readOnly={props.readonly}/>
      <label  className="Input__label" htmlFor="">{props.placeHolder}</label>
    </div>
);
};


export default input;
*/

import * as React from "react";
import TextField from "@mui/material/TextField";
import "./Input.scss";

export default function BasicTextFields({
  placeHolder,
  type,
  change,
  fullWidth,
  value,
}) {
  return (
    <div className="Input__container">
      <TextField
        fullWidth={fullWidth}
        margin="dense"
        id="outlined-basic"
        className=""
        label={placeHolder}
        variant="outlined"
        type={type}
        onChange={change}
        value={value}
      />
    </div>
  );
}
