import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AdminEditUser({ show, cancel, user, sendEditUser }) {
  console.log("user", user);
  const [name, setName] = useState(" ");
  const [lastName, setLastName] = useState(" ");
  const [expires, setExpires] = useState(user.expires);
  const [birthday, setBirthday] = useState(user.birthday);
  const [email, setEmail] = useState(user.email);

  const editUSer = () => {
    
    sendEditUser({
      name,
      lastName,
      expires,
      birthday,
      email,
    });
  };

  useEffect(() => {
    setName(user.name);
    setLastName(user.last_name);
    setExpires(user.expires);
    setBirthday(user.birthday);
    setEmail(user.email);
  }, [user]);

  return (
    <Dialog
      open={show}
      scroll="body"
      TransitionComponent={Transition}
      keepMounted
      onClose={cancel}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        Edit User <Edit />
      </DialogTitle>
      <form onSubmit={() => editUSer()}>
        <DialogContent sx={{ display: "flex", alignItems: "center" }}>
          <Box style={{ width: "30rem" }}>
            <Stack gap={2}>
              <TextField
                label="Name"
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                required
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                required
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Birthdate"
                    value={dayjs(moment(birthday).format("YYYY-MM-DD"))}
                    onChange={(newDate) => setBirthday(newDate)}
                  />
                </DemoContainer>
                <DemoContainer fullWidth components={["DatePicker"]}>
                  <DatePicker
                    label="Expires"
                    value={dayjs(moment(expires).format("YYYY-MM-DD"))}
                    onChange={(newDate) => setExpires(newDate)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="button" variant="outlined" onClick={cancel}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
