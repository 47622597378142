import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function BasicCard({children, styles={}}) {
  return (
    <Card >
      <CardContent sx={styles}>{children}</CardContent>
    </Card>
  );
}
