import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import axios from "axios";
import Card from "../../../../components/UI/Card/Card";
import Table from "../../../../components/UI/Table/Table";
import H from "../../../../components/UI/H/H";
import Button from "../../../../components/UI/Button/Button";
import {
  Add,
  Edit,
  Undo,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { BASE_URL_IMAGES } from "../../../../constants";
import { ButtonGroup } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Dialog from "../../../../components/UI/Dialog/Dialog"
import "./EditLevelWords.scss";
import NewEditFormWord from "./NewEditFormWord/NewEditFormWord";

const EditLevelWords = () => {
  const [words, setWords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [wordform, setWordForm] = useState();
  const [dialog, setDialog] = useState({});
  
  const title = searchParams.get("title")
    ? searchParams.get("title").replace("+", " ")
    : "";
  const id = searchParams.get("id") ? searchParams.get("id") : 0;

  useEffect(() => {
    getWordsByLevel();
  }, []);

  const closeDialogHandler = () => {
    setDialog({show : false})
  }
  
  const dialogTest = () => {
    setDialog({
      show: true,
      type: "success",
      accept_text: "great!",
      accept: closeDialogHandler,
      text: "Success!",
    });
  }

  const getWordsByLevel = () => {
    axios
    .get("/word/get-by-level/" + id)
    .then(({ data }) => {
      if (data.length > 0) {
        const wordsArray = data.map((word) => {
          return {
            key: word.id,
            id: word.id,
            word: word.text,
            src_img: word.img,
            src_sound: word.audio,
            enabled: word.enabled,
          };
        });
        setWords(wordsArray);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const enableWordHandler = (word) => {
    console.log(word);
  };

  const closeEditWordHandler = () => {
    setWordForm({
      show: false,
    });
  };

  const newWordHandler = () => {
    setWordForm({
      show: true,
      edit: false,
      idLevel: id,
      levelName: title,
      cancel: closeEditWordHandler,
      onOk: getWordsByLevel
    });
  };

  const editWordHandler = (word) => {
    console.log(word);
    setWordForm({
      show: true,
      edit: true,
      idLevel: id,
      levelName: title,
      wordEdit: word.word,
      wordImageEdit: word.src_img,
      wordSoundEdit: word.src_sound,
      cancel: closeEditWordHandler,
      onOk: getWordsByLevel
    });
  };

  const columns = [
    {
      field: "word",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Word",
      width: 100,
      height: 100,
    },

    {
      field: "image",
      headerName: "Image",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      height: 100,
      renderCell: ({ row }) => {
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "5rem", height: "5rem", marginRight: "2rem" }}
              src={BASE_URL_IMAGES + "/words/" + row.src_img}
              alt={row.src_img}
            />
            {row.name}
          </span>
        );
      },
    },
    {
      field: "enabled",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Enabled",
      width: 50,
      height: 100,
      renderCell: ({ row }) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              click={() => {
                enableWordHandler(row);
              }}
            >
              {row.enabled === 1 ? <Visibility /> : <VisibilityOff />}
            </Button>
          </div>
        );
      },
    },
    {
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      width: 20,
      height: 100,
      renderCell: ({row}) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            
              <Button click={() => {editWordHandler(row)}}>
                <Edit />
              </Button>
          </div>
        );
      },
    },
  ];
  console.log(wordform)
  return (
    <>
      <div className="EditLevelWords">
        <H type="1">Words</H>
        <Card>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <H type="3">{title}</H>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" style={{ position: "relative" }}>
                <NavLink
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  to={"/admin/levels"}
                ></NavLink>
                Back to Levels <Undo />
              </Button>
              <ButtonGroup sx={{ margin: "0 2rem" }}>
                <Button variant="outlined" style={{ position: "relative" }}>
                  <NavLink
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    to={"/admin/levels/sentences?id=" + id + "&title=" + title}
                  ></NavLink>
                  Sentences
                </Button>

                <Button variant="contained" >
                  Words
                </Button>
              </ButtonGroup>
              <Button variant="contained" click={newWordHandler}>
                New <Add />
              </Button>
            </div>
            <br />
            <Table rows={words} columns={columns} pageSize={5} />
          </div>
        </Card>
      </div>
      <NewEditFormWord {...wordform} />
      <Dialog {...dialog} />
    </>
  );
};

export default EditLevelWords;
