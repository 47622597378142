import axios from "axios";

export async function getGamesService() {
  try {
    const readings = await axios.get("/games");

    return readings.data;
  } catch (error) {
    return [];
  }
}

export async function getDontPopDataService(user) {
  try {
    const readings = await axios.get("/games/dont-pop/" + user);

    return readings.data;
  } catch (error) {
    return [];
  }
}
