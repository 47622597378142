import axios from "axios";

export async function sendProgressService(sentences, level_id, user_id) {
  try {
    const data = {
      sentences,
      level_id,
      user : user_id,
    };
    const readings = await axios.put("/sentence/sentence-progress", data);

    return readings.data;
  } catch (error) {}
}
