import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Card from "../../../components/UI/Card/Card";
import Table from "../../../components/UI/Table/Table";
import H from "../../../components/UI/H/H";
import Select from "../../../components/UI/Select/Select";
import Button from "../../../components/UI/Button/Button";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import { BASE_URL_IMAGES } from "../../../constants";

import "./AdminLevels.scss";
import Dialog from "../../../components/UI/Dialog/Dialog"
import { Container } from "@mui/material";
import GoBack from "../../../components/UI/GoBack/GoBack";

const AdminLevels = () => {
  const [difficulties, setDifficulties] = useState([]);
  const [difficulty, setDifficulty] = useState("");
  const [section, setSection] = useState("");
  const [sections, setSections] = useState([]);
  const [levels, setLevels] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  console.log(dialog);

  useEffect(() => {
    axios
      .get("/difficulty")
      .then(({ data }) => {
        if (data.length > 0) {
          const difficultyArray = data.map((difficulty) => {
            return {
              key: difficulty.id,
              value: difficulty.id,
              name: difficulty.name,
            };
          });
          setDifficulties(difficultyArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (difficulty !== null) {
      axios
        .get("/section/section-by-difficulty/" + difficulty)
        .then(({ data }) => {
          if (data.length > 0) {
            const sectionsArray = data.map((section) => {
              return {
                key: section.id,
                value: section.id,
                name: section.name,
              };
            });
            setSections(sectionsArray);
            setSection("");
            setLevels([]);
          } else {
            setSections([]);
            setSection("");
            setLevels([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [difficulty]);

  useEffect(() => {
    if (section) {
       getLevelsBySectionHandler();
    }
  }, [section]);

  const getLevelsBySectionHandler =  () => {
    axios
    .get("/levels/get-by-section/" + section)
    .then(({ data }) => {
      if (data.length > 0) {
        const levelsArray = data.map((level) => {
          return {
            key: level.id,
            id: level.id,
            name: level.name,
            src: level.src,
            enabled: level.enabled,
          };
        });
        setLevels(levelsArray);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const closeDialogHandler = () => {
    setDialog({show : false})
  }

  const enableLevelRequestHandler = (id, enabled) => {
    const data = {
      id : id,
      enabled : enabled
    }

    axios
        .put("/levels/enable-disable", data)
        .then(() => {
          getLevelsBySectionHandler()
          setDialog({
            show : true,
            type: "success",
            accept_text : "great!",
            accept : closeDialogHandler,
            text : "Success!"
          })
        })
        .catch((error) => {
          console.log(error);
          setDialog({
            show : true,
            type: "error",
            accept_text : "okay :(",
            accept : closeDialogHandler,
            text : "Something went wrong, please try again"
          })
        });
  }

  const enableLevelHandler = (level) => {
    console.log(level)
    const text = level.enabled === 1 ? "DISABLE" : "ENABLE" ;
    const enabled = level.enabled === 1 ? 0 : 1 ;
    setDialog({
      text : "Are you sure you want to " + text + " this level?",
      type: "warning" ,
      accept: () => {enableLevelRequestHandler(level.id, enabled)},
      accept_text : "yes!",
      cancel_text : "nope!",
      show : true,
      cancel: closeDialogHandler
    })
    console.log(dialog)
  };

  const columns = [
    {
      field: "name",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Name",
      width: 200,
      height: 100,
      renderCell: ({ row }) => {
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "5rem", height: "5rem", marginRight: "2rem" }}
              src={BASE_URL_IMAGES + "/words/" + row.src}
              alt={row.src}
            />
            {row.name}
          </span>
        );
      },
    },
    {
      field: "enabled",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Enabled",
      width: 100,
      height: 100,
      renderCell: ({ row }) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              click={() => {
                enableLevelHandler(row);
              }}
            >
              {row.enabled === 1 ? <Visibility /> : <VisibilityOff />}
            </Button>
          </div>
        );
      },
    },
    {
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      width: 50,
      height: 100,
      renderCell: ({row}) => {
        console.log(`/admin/levels/sentences?id=${row.id}&title=${row.name.split("  ").join(" ").split(" ").join("+")}`)
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button>
              <NavLink to={`/admin/levels/sentences?id=${row.id}&title=${row.name.split("  ").join(" ").split(" ").join("+")}`}>
                <Edit />
              </NavLink>
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Container sx={{position:"relative"}}>
      <GoBack to="/levels"/>
      <div className="AdminLevels">
        <H type="1">Levels</H>
        <Card styles={{ display: "flex" }}>
          <Select
            title="Difficulty"
            options={difficulties}
            defaultValue={difficulty}
            change={(difficulty) => {
              setDifficulty(difficulty);
            }}
          />
          <Select
            title="Sections"
            options={sections}
            defaultValue={section}
            change={(section) => {
              setSection(section);
            }}
          />
        </Card>

        <br />
        <Table rows={levels} columns={columns} pageSize={5} />
      </div>
      <Dialog  {...dialog} />
    </Container>
  );
};

export default AdminLevels;
