import { useState, useEffect } from "react";
import { BASE_URL_SOUNDS } from "../../constants";

import "./Sound.scss";

const Sound = ({
  click,
  src,
  autoplay,
  icon,
  size,
  position,
  children,
  newclass,
}) => {
  const [sound, setSound] = useState(null);
  const [path, setPath] = useState(null);
  const [played, setPlayed] = useState(false);

  const playSoundHandler = () => {
    if(sound){
      sound.play();
      setPlayed(true);
  
      if (click) {
        click();
      }
    }
    
  };
  useEffect(() => {
    if (sound === null || path !== src) {
      let path = src;
      console.log(BASE_URL_SOUNDS + `/${path}`);

      let audio = new Audio(BASE_URL_SOUNDS + `/${path}`);
      setSound(audio);
      setPath(path);
      setPlayed(false);
    }

  }, [autoplay, path, sound, src]);

  if (autoplay && !played) {
    playSoundHandler();
    setPlayed(true);
  }

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      if (sound) {
        sound.pause();
      }
    };
  }, [sound]);

  let iconRender = null;
  if (icon) {
    iconRender = (
      <i
        className={
          (size ? "Sound__icon-small" : "") +
          " material-icons Sound__icon " +
          (position === "right" ? " Sound__icon-right " : "") +
          " animated tada"
        }
      >
        volume_up
      </i>
    );
  }

  return (
    <div
      onClick={playSoundHandler}
      className={"Sound " + (newclass !== "" ? newclass : "")}
    >
      {iconRender}
      {children}
    </div>
  );
};

export default Sound;
