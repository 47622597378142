import React from "react";
import Fire from "../../../../assets/images/icons/fire.png";

import "./Streak.scss";
import {   Stack, Typography } from "@mui/material";

export default function Streak() {
  const streak = localStorage.getItem("streak");
  let day = "day";
  if (streak > 1) {
    day = "days";
  }

  return (
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <img
          className="Streak__icon animated pulse infinite"
          src={Fire}
          alt="Fire"
        />
        <Typography variant="h5">
          {streak} {day} streak!
        </Typography>
      </Stack>
  );
}
