import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
} from "@mui/material";
import { Add, Check, Edit } from "@mui/icons-material";
import Input from "../../../../../components/UI/Input/Input";
import H from "../../../../../components/UI/H/H";
import { BASE_URL_IMAGES, BASE_URL_SOUNDS } from "../../../../../constants";
import AlertDialog from "../../../../../components/UI/Dialog/Dialog";
import Sound from "../../../../../hoc/Sound/Sound";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewEditFormSentence({
  idLevel,
  sentenceEdit = "",
  missingWordEdit = "",
  imageSrcEdit = "",
  imageSoundSrcEdit = "",
  edit,
  show,
  cancel,
  levelName,
  sentenceId,
  onOk,
  sentence_src
}) {
  const [sentence, setSentence] = useState(sentenceEdit);
  const [missingWord, setMissingWord] = useState(missingWordEdit);
  const [missingWordSound, setMissingWordSound] = useState(null);
  const [missingWordImage, setMissingWordImage] = useState(null);
  const [sentenceSound, setSentenceSound] = useState(null);
  const [actualSentenceSound, setActualSentenceSound] = useState(null);
  const [imageSrc, setImageSrc] = useState(imageSrcEdit);
  const [imageSoundSrc, setImageSoundSrc] = useState(imageSoundSrcEdit);
  const [dialog, setDialog] = useState({});

  useEffect(() => {
    if (sentenceEdit !== "") {
      setSentence(sentenceEdit);
    }
    if (missingWordEdit !== "") {
      setMissingWord(missingWordEdit);
    }
    if (imageSrcEdit !== "") {
      setImageSrc(imageSrcEdit);
    }
    if (imageSoundSrcEdit !== "") {
      setImageSoundSrc(imageSoundSrcEdit);
    }
  }, [sentenceEdit, missingWordEdit, imageSrcEdit, imageSoundSrcEdit]);

  useEffect(() => {
    if (!edit) {
      setSentence("");
      setMissingWord("");
      setImageSrc("");
      setImageSoundSrc("");
      setMissingWordSound(null);
      setMissingWordImage(null);
      setSentenceSound(null);
    }
  }, [edit]);

  const validateSentence = (edit) => {
    let message = "";
    if (missingWord === "") {
      message = "Please fill the missing word!";
    } else if (sentence === "") {
      message = "Please fill the sentence!";
    } else if (sentenceSound === null && !edit) {
      message = "Please upload a sentence audio!";
    } else if (missingWordSound === null && !edit) {
      message = "Please upload a missing word audio!";
    } else if (sentence.split("__").length !== 2) {
      message = 'Sentence must have "__" symbol and only one time!';
    }
    if (message !== "") {
      setDialog({
        text: message,
        type: "warning",
        accept: () => {
          setDialog({ show: false });
        },
        accept_text: "Ok!",
        show: true,
      });
      return false;
    } else {
      return true;
    }
  };

  const editSentence = () => {
    const validSentence = validateSentence(true);

    if (validSentence) {
      let formData = new FormData();
      formData.append("sentence", sentence);
      formData.append("m_word", missingWord);
      if (sentenceSound) {
        formData.append("sentence_sound", sentenceSound);
      }
      if (missingWordSound) {
        formData.append("m_word_sound", missingWordSound);
      }
      if (missingWordImage) {
        formData.append("sentence_img", missingWordImage);
      }

      formData.append("path", String(levelName).toLowerCase());
      formData.append("name", missingWord.toLowerCase());
      formData.append("id_sentence", sentenceId);

      axios({
        url: "/sentence",
        method: "put",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          onOk()
          setDialog({
            text: "Sentence edited!",
            type: "success",
            accept: () => {
              cancel();
              setDialog({ show: false });
            },
            accept_text: "Great!",
            show: true,
          });

          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          setDialog({
            text: "Something went wrong...! Please try again!",
            type: "error",
            accept: () => {
              setDialog({ show: false });
            },
            accept_text: "Ok :(",
            show: true,
            cancel: () => {
              setDialog({ show: false });
            },
          });
        });
    }
  };

  const submitSentence = () => {
    const validSentence = validateSentence(false);

    if (validSentence) {
      let formData = new FormData();
      formData.append("sentence", sentence);
      formData.append("m_word", missingWord);
      formData.append("sentence_sound", sentenceSound);
      formData.append("m_word_sound", missingWordSound);
      if (missingWordImage) {
        formData.append("sentence_img", missingWordImage);
      }
      formData.append("path", String(levelName).toLowerCase());
      formData.append("name", missingWord.toLowerCase());
      formData.append("id_level", idLevel);

      axios({
        url: "/sentence",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          onOk()
          setDialog({
            text: "Sentence created!",
            type: "success",
            accept: () => {
              cancel();
              setDialog({ show: false });
            },
            accept_text: "Great!",
            show: true,
          });

          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          setDialog({
            text: "Something went wrong...! Please try again!",
            type: "error",
            accept: () => {
              setDialog({ show: false });
            },
            accept_text: "Ok :(",
            show: true,
            cancel: () => {
              setDialog({ show: false });
            },
          });
        });
    }
  };

  return (
    <>
      <Dialog
        open={show}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
        onClose={cancel}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          {edit ? (
            <>
              Edit Sentence <Edit />
            </>
          ) : (
            <>
              New Sentence <Add />
            </>
          )}
        </DialogTitle>
        <DialogContent sx={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "30rem" }}>
            <Input
              fullWidth
              placeHolder="Sentence"
              change={(event) => {
                setSentence(event.target.value);
              }}
              value={sentence}
            />
            <Input
              fullWidth
              placeHolder="Missing Word"
              change={(event) => {
                setMissingWord(event.target.value);
              }}
              value={missingWord}
            />
            <Input
              fullWidth
              placeHolder="Complete Sentence"
              value={sentence.replace("__", missingWord)}
            />
            <H type="5">Sentence sound</H>
            <Card variant="outlined" sx={{ marginBottom: "2rem" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
              >
                <Button variant="contained" component="label">
                  {sentenceSound ? (
                    <>
                      Uploaded <Check />
                    </>
                  ) : (
                    "Upload"
                  )}
                  <input
                    hidden
                    accept="audio/*"
                    type="file"
                    onChange={(event) => {
                      setSentenceSound(event.target.files[0]);
                      /*const file = event.target.files[0]
                      const fileReader = new FileReader();
                      fileReader.onload = (e) => {
                        const { result } = e.target;
                        if (result) {
                          setActualSentenceSound(result)
                        }
                      }
                      fileReader.readAsDataURL(file);

                      console.log(event.target.files[0]);
                      */
                    }}
                  />
                </Button>
                <Sound
                  icon
                  size
                  position="rigth"
                  //src={actualSentenceSound}
                  src={`sentences/${sentenceId}.${sentence_src}`}
                >
                  <img
                    style={{ width: "6rem", height: "6rem" }}
                    src={BASE_URL_IMAGES + "/words/others/bocina.png"}
                    alt={"speaker"}
                  />
                </Sound>
              </Stack>
            </Card>
            <H type="5">Missing word sound</H>
            <Card variant="outlined" sx={{ marginBottom: "2rem" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
              >
                <Button variant="contained" component="label">
                  {missingWordSound ? (
                    <>
                      Uploaded <Check />
                    </>
                  ) : (
                    "Upload"
                  )}
                  <input
                    hidden
                    accept="audio/*"
                    type="file"
                    onChange={(event) => {
                      setMissingWordSound(event.target.files[0]);
                    }}
                  />
                </Button>
                <Sound icon size position="rigth" src={imageSoundSrc}>
                  <img
                    style={{ width: "6rem", height: "6rem" }}
                    src={BASE_URL_IMAGES + "/words/others/bocina.png"}
                    alt={"speaker"}
                  />
                </Sound>
              </Stack>
            </Card>
            <H type="5">Missing word image</H>
            <Card variant="outlined">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
              >
                <Button variant="contained" component="label">
                  {missingWordImage ? (
                    <>
                      Uploaded <Check />
                    </>
                  ) : (
                    "Upload"
                  )}
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      setMissingWordImage(event.target.files[0]);
                    }}
                  />
                </Button>
                <img
                  style={{ width: "6rem", height: "6rem" }}
                  src={
                    BASE_URL_IMAGES +
                    "/words/" +
                    (imageSrc ? imageSrc : "others/bocina.png")
                  }
                  alt={"speaker"}
                />
              </Stack>
            </Card>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              edit ? editSentence() : submitSentence();
            }}
          >
            {edit ? "Edit" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog {...dialog} />
    </>
  );
}
