import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import Table from "../../../components/UI/Table/Table";

import "./AdminReadings.scss";
import {
  Box,
  Container,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  getReadingsService,
  enableReadingService,
} from "../../../services/reading.service";
import Button from "../../../components/UI/Button/Button";
import Dialog from "../../../components/UI/Dialog/Dialog";
import { DataGrid } from "@mui/x-data-grid";
import GoBack from "../../../components/UI/GoBack/GoBack";

export default function AdminReadings() {
  const [readings, setReadings] = useState([]);
  const [dialog, setDialog] = useState({});
  const [search, setSearch] = useState("");
  useEffect(() => {
    const getReadings = async () => {
      const readings_array = await getReadingsService();
      if (readings_array.length > 0) {
        setReadings(readings_array);
      }
    };
    getReadings();
  }, []);

  const enableReadingHandler = (reading) => {
    const text = reading.enabled === 1 ? "DISABLE" : "ENABLE";
    setDialog({
      text: "Are you sure you want to " + text + " this level?",
      type: "warning",
      accept: () => {
        enableReadingService(reading.id, reading.enabled);
        const index = readings.findIndex((item) => item.id === reading.id);
        const readings_item = [...readings];
        readings_item[index].enabled =
          readings_item[index].enabled === 1 ? 0 : 1;
        setReadings(readings_item);
        setDialog({
          show: true,
          type: "success",
          accept_text: "great!",
          accept: closeDialogHandler,
          text: "Success!",
        });
      },
      accept_text: "yes!",
      cancel_text: "nope!",
      show: true,
      cancel: closeDialogHandler,
    });
    console.log(dialog);
  };
  const closeDialogHandler = () => {
    setDialog({ show: false });
  };
  const columns = [
    {
      field: "title",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Title",
      width: 500,
      renderCell: ({ row }) => {
        return <Typography variant="body1">{row.title}</Typography>;
      },
    },
    {
      field: "enabled",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Enabled",

      renderCell: ({ row }) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              click={() => {
                enableReadingHandler(row);
              }}
            >
              {row.enabled === 1 ? <Visibility /> : <VisibilityOff />}
            </Button>
          </div>
        );
      },
    },
    {
      headerName: "Edit",
      sortable: false,
      disableColumnMenu: true,

      renderCell: ({ row }) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button>
              <NavLink to={`/admin/readings/edit?id=${row.id}`}>
                <Edit />
              </NavLink>
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Container sx={{position:"relative"}}>
      <GoBack to="/levels" />
      <Box alignItems={"flex-start"} sx={{ width: "80%", margin: "0 auto" }}>
        <Typography variant="h2" gutterBottom>
          Readings
        </Typography>

        <Stack direction={"row"} gap={2}>
          <Button
            click={() => {
              window.location.replace("/admin/readings/new");
            }}
            variant={"contained"}
            style={{ marginBottom: "1rem" }}
          >
            new +
          </Button>
          <TextField
            variant="outlined"
            label="Search"
            multiline
            value={search}
            
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Stack>
        <Box sx={{ height: "60vh" }}>
          <DataGrid
            rows={
              search !== ""
                ? readings.filter(
                    (reading) =>
                      reading.title.toUpperCase().includes(search.toUpperCase())
                      
                  )
                : readings
            }
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection={false}
            disableRowSelectionOnClick
            autoPageSize
          />
        </Box>
      </Box>
      <Dialog {...dialog} />
    </Container>
  );
}
