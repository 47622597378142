import React, { PureComponent } from 'react';
import axios from 'axios';

import H from '../../../components/UI/H/H';
import Input from '../../../components/UI/Input/Input';
import Doty from '../../../components/UI/Doty/Doty';
import Option from '../../../components/UI/Option/Option'
import Bomb from './Bomb/Bomb';
import Heart from '../../../assets/images/DotBombs/heart.png';

import './DotBombs.scss';

class DotBombs extends PureComponent {
  state={
    place:'start',
    difficulty: 'easy',
    game: null,
    search:'',
    pose:null,
    dotyPose:'12',
    score:0,
    bombs: null,
    playing:false,
    amount :0,
    fallDistance: 50,
    interval:null,
    lives : 5,
    bombQty:null,
    win:1000,
    data: [
        {
            "id": 1,
            "title": "alphabet",
            "src": "abc.jpg"
        },
        {
            "id": 2,
            "title": "numbers",
            "src": "numbers.gif"
        },
        {
            "id": 3,
            "title": "days of the week",
            "src": "days.png"
        },
        {
            "id": 4,
            "title": "color",
            "src": "colors.jpg"
        },
        {
            "id": 5,
            "title": "months of the year",
            "src": "months.jpg"
        },
        {
            "id": 6,
            "title": "family",
            "src": "family.jpg"
        },
        {
            "id": 7,
            "title": "clothes",
            "src": "clothes.png"
        },
        {
            "id": 8,
            "title": "verbs",
            "src": "verbs.jpg"
        },
        {
            "id": 9,
            "title": "time",
            "src": "time.jpg"
        },
        {
            "id": 10,
            "title": "shapes",
            "src": "shapes.jpg"
        },
        {
            "id": 11,
            "title": "kitchen elements",
            "src": "kitchen.jpg"
        },
        {
            "id": 12,
            "title": "foods",
            "src": "meals.jpg"
        },
        {
            "id": 13,
            "title": "meals",
            "src": "food.jpg"
        },
        {
            "id": 14,
            "title": "sports",
            "src": "sports.gif"
        },
        {
            "id": 15,
            "title": "stations",
            "src": "stations.jpg"
        },
        {
            "id": 16,
            "title": "weather",
            "src": "stations.jpg"
        },
        {
            "id": 17,
            "title": "professions",
            "src": "stations.jpg"
        },
        {
            "id": 18,
            "title": "personal care products",
            "src": "stations.jpg"
        }
    ]
  }

  componentDidMount (){
    console.log(this.props);
    const data = {
      id: 0
    }
    axios.post("/getGameWords",data)
    .then(response => {
      this.setState({data:response.data});
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    })
  }


  optionHandler = (type) => {
    let bombs = 0;
    let game = false;
    switch (type) {
      case 'normal':
        this.setState({place:'difficulties',game:'normal',text:"Survive until you complete all bombs"})
        break;
        case 'endurance':
          this.setState({bombQty:5,place:'pre-game',game:'endurance',text:"Survive as much as you can!",amount:0.01,interval:5})
          break;
          case 'game':
            game = true;
            this.setState({place:'game',pose:'14',score:0,lives:5})
          break;
          case 'easy':
            bombs = 2;
            this.setState({bombQty:bombs,place:'pre-game',pose:'14',difficulty:'easy',amount:0.1,interval:5})
          break;
          case 'medium':
            bombs = 3;
            this.setState({bombQty:bombs,place:'pre-game',pose:'14',difficulty:'medium',amount:0.3,interval:3})
          break;
          case 'hard':
            bombs = 5;
            this.setState({bombQty:bombs,place:'pre-game',pose:'14',difficulty:'hard',amount:0.5,interval:2})
          break;
          case 'change':
            this.setState({place:'start'})
          break;
          case 'restart':
          let text;
          if(this.state.game === 'normal'){
            text = "Survive until you complete all bombs"
          }else{
            text = "Survive as much as you can!"
          }
            this.setState({place:'pre-game',text:text})
          break;
          case 'exit':
            window.location.reload(false);
            break;
      default:

    }
    if (game){
      this.bombBuilderHandler(this.state.bombQty);

    }
  }

  bombBuilderHandler = (bombs) => {
    const bombsArray = [];
    for (var i = 0; i < bombs; i++) {
      bombsArray.push({title:'',src:'bomb',distance:0,falling:false});
    }
    console.log(bombsArray);
    this.setState({bombs:bombsArray});
    this.bombHandler()
    this.bombDropHandler()
  }

  bombHandler = () => {
    console.log("bolbHandler");

    setTimeout(() => {

      if(this.state){

        if(this.state.interval && this.state.place === "game"){

          const bombs = [...this.state.bombs];
            let free = [];
            bombs.forEach((bomb, i) => {
              if(!bomb.falling && bomb.distance === 0){
                free.push(i);
              }
            });
            console.log(free);
            if(free.length > 0){
              const index = Math.floor(Math.random() * (free.length));

              let exists = true;
              let trys = 0;
              let indexImg = Math.floor(Math.random() * (this.state.data.length -1));
              while (exists && trys < this.state.data.length) {

                exists = false;
                this.state.bombs.forEach((item, i) => {
                    if(this.state.data[indexImg].title === item.title ){
                      exists = true;
                    }
                });
                if(exists){
                  if (indexImg >= this.state.data.length -1 ){
                    indexImg = 0;
                  }else{
                    indexImg++
                  }
                }
                trys ++;
              }
              if(!exists){
                bombs[free[index]].src = this.state.data[indexImg].src;
                bombs[free[index]].title = this.state.data[indexImg].title;

                bombs[free[index]].falling = true;
              }
              console.log(bombs);
              this.setState({bombs:bombs})
            }
          }
      }

      if(this.state.place === "game" ){
          this.bombHandler();
      }
      }, (this.state.interval*1000));


  }



  bombDropHandler = () => {
    console.log("DROPS");
    setTimeout( () => {

        if(this.state.bombs && this.state.place === "game"){

          const bombs = [...this.state.bombs];
          bombs.forEach((bomb, i) => {
            if(bomb.falling ){
              if(bomb.distance < this.state.fallDistance){
                if(this.state.game === "endurance"){
                  let amount = this.state.amount + 0.0001;
                  let interval = this.state.interval - 0.0005;
                  this.setState({interval:interval,amount:amount})
                }
                bomb.distance = bomb.distance + this.state.amount;
              }else{
                bomb.src="explotion";
                bomb.falling = false;
                let lives = this.state.lives - 1;
                console.log("lives " + lives);
                this.setState({dotyPose:'05',lives:lives,bombs:bombs});
                let timeout;
                if(lives > 0){
                  timeout = setTimeout( () => {
                    bomb.distance = 0;
                    bomb.src = 'bomb';
                    bomb.title = '';
                    this.setState({dotyPose:'14'});
                  }, 1500);
                }else{
                  clearTimeout(timeout);
                  this.setState({place:"endgame",text:'You exploded!',pose:"05"})
                }
              }
            }
          });

          this.setState({bombs:bombs})
        }
        if(this.state.place === "game" ){
          this.bombDropHandler()
        }

      }, 100);
  }

  lookForWordHandler = (value) => {
    let text = value.target.value.toUpperCase();
    this.setState({search:text})
    if(text !== ''){
      const bombs = [...this.state.bombs];
      bombs.forEach((bomb, i) => {
          if(text === bomb.title.toUpperCase()){
            this.setState({search:''})
            bomb.src="correct";
            bomb.falling = false;
            let difference = this.state.fallDistance - bomb.distance;
            if(this.state.difficulty === 'easy'){
              difference = difference * 3;
            }else if(this.state.difficulty === 'medium'){
              difference = difference * 2;
            }
            let score = this.state.score + difference;
            if(score > 1000 && this.state.game === 'normal'){
              score = 1000;
              this.setState({score:Math.floor(score),place:"endgame",text:'You WIN!'})
            }else{
              this.setState({score:Math.floor(score)})
            }
            bomb.title='';
            value.target.value = "";
            setTimeout( () => {
              bomb.src='bomb';
              bomb.distance = 0;
            }, 2500);
          }
      });
      this.setState({bombs:bombs})
    }
  }



  render(){
    let content = null;
    switch (this.state.place) {
      case 'start':
         content = (
            <>
              <H newclass="u-margin-bot-medium" type="1">Dot BOMBS!</H>
              <Option margin click={() => {this.optionHandler('normal')}}>Normal</Option>
              <Option margin click={() => {this.optionHandler('endurance')}}>Survival</Option>
              <Option margin click={() => {this.optionHandler('exit')}}>Go back to games</Option>
            </>
          );
        break;
        case 'difficulties':
           content = (
              <>
                <H newclass="u-margin-bot-medium" type="1">Choose a difficulty!</H>
                <Option margin click={() => {this.optionHandler('easy')}}>Easy</Option>
                <Option margin click={() => {this.optionHandler('medium')}}>Medium</Option>
                <Option margin click={() => {this.optionHandler('hard')}}>Hard</Option>
              </>
            );
          break;
        case 'game':
            let bombs = null;
            if(this.state.bombs){
              bombs = this.state.bombs.map((bomb, index) => {
                return <Bomb key={index} src={bomb.src} title={bomb.title} distance={bomb.distance} fallDistance={this.state.fallDistance}/>
              })

            }
            let count = 0;
            let lives = [];
            while (count < this.state.lives) {
                count ++;
                lives.push(1);
            }

            lives = lives.map(item => {
              return <img className="DotBombs__heart" src={Heart} alt=""/>
            })


            content = (
              <>
                <div className="DotBombs__game">
                  <div className="DotBombs__gameplay">

                    <div className="DotBombs__bombs">
                      {bombs}
                    </div>
                    <div className="DotBombs__doty-score">
                      <div className="DotBombs__doty-score-container"> 
                        <H type="2">Score: {this.state.score}
                        <Doty pose={this.state.dotyPose} size="smaller DotBombs__toggle2" />
                        </H>
                      </div>

                      <div className="DotBombs__hearts-container">
                        {lives}
                      </div>
                      <Input
                        type="text"
                        placeHolder="Type here!"
                        value={this.state.search}
                        change={(event) => {this.lookForWordHandler(event)}}/>

                      <Doty pose={this.state.dotyPose} size="big DotBombs__toggle" />
                      <div onClick={() => {this.setState({place:'endgame',text:'Game ended',pose:"05"})}} className="DotBombs__exit">
                        Exit
                        <Doty size="smaller" pose="05"  />
                      </div>

                    </div>
                  </div>

                </div>

              </>

             );

         break;
         case 'pre-game':
         content = (
           <div className="DotBombs__pregame">
             <H newclass="u-margin-bot-medium" type="1">{this.state.text}</H>
             <Option margin click={() => {this.optionHandler('game')}}>start!</Option>
           </div>
          );
          break;
      case 'endgame':
      let difficulty = null;
      if(this.state.game === 'normal'){
        difficulty = <H type="3">Difficulty: {this.state.difficulty}</H>;
      }
      content = (
         <>
           <H type="1">{this.state.text}</H>
           <Doty pose={this.state.pose} size="medium" />
           <H type="3">Game mode: {this.state.game}</H>
           {difficulty}
           <Option margin click={() => {this.optionHandler('restart')}}>Restart</Option>
           <Option margin click={() => {this.optionHandler('change')}}>Change game mode</Option>
           <Option margin click={() => {this.optionHandler('exit')}}>Back to games</Option>
         </>
       );
       break;
      default:
          content = null;
    }
    return(
      <div className="DotBombs">
        {content}
        {this.state.handler}
      </div>
    );
  }
}

export default DotBombs;
