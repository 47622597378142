

import  Button from '../Button/Button';
import { Stack } from '@mui/material';

import './ConfirmButtons.scss';

const ConfirmButtons = ({
  click1,
  click2,
  btnClass1,
  btnClass2,
  name1,
  name2
})  => {
  console.log("name2", name2);

  return (
    <Stack className="ConfirmButtons" direction="row"
    alignItems="center"
    justifyContent="space-around"
    spacing={4}>
      <Button fullWidth variant="outlined" click={click1} customClass={btnClass1 + " half"}>{name1}</Button>
      <Button fullWidth variant="outlined" click={click2} customClass={btnClass2 +" half"}>{name2}</Button>
    </Stack>
  );

}

export default ConfirmButtons;
