import axios from "axios";

export async function getReadingsService() {
  try {
    const readings = await axios.get("/reading");

    return readings.data;
  } catch (error) {}
}

export async function getEnabledReadingsService(user) {
  try {
    const readings = await axios.get("/reading/enabled/" + user.id);

    return readings.data;
  } catch (error) {}
}
export async function enableReadingService(id_reading, enabled) {
  try {
    const readings = await axios.put("/reading/enable", {
      id_reading,
      enabled,
    });

    return readings.data;
  } catch (error) {}
}

export async function readingByIdService(id_reading) {
  try {
    const reading = await axios.get(`/reading/${id_reading}`);

    return reading.data;
  } catch (error) {}
}

export async function updateReadingService(id_reading, text, title, audio) {
  try {
    let formData = new FormData();
    formData.append("id_reading", id_reading);
    formData.append("text", text);
    formData.append("title", title);
    formData.append("audio", audio);
    await axios.put(`/reading`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return true;
  } catch (error) {
    return false;
  }
}

export async function newReadingService(text, title, audio) {
  try {
    let formData = new FormData();
    formData.append("text", text);
    formData.append("title", title);
    formData.append("audio", audio);
    await axios.post(`/reading`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return true;
  } catch (error) {
    return false;
  }
}
