import React, { useState, useEffect } from "react";
import { BASE_URL_IMAGES } from "../../../constants";

import "./WordImg.scss";

export default function WordImg({ size, opacity, src, customClass }) {
  const [img, setImg] = useState(null);

  useEffect(() => {
    setImg(`${BASE_URL_IMAGES}/words/${src}`);
  }, [src]);

  useEffect(() => {}, [opacity]);

  let styleClass = ["WordImg"];
  if (size) {
    styleClass[1] = size;
  }
  if (customClass) {
    styleClass[2] = customClass;
  }
  return (
    <img
      className={styleClass.join(" ")}
      
      src={img}
      alt=""
      style={{ opacity: opacity }}
    />
  );
}
