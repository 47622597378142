import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  redirect,
} from "react-router-dom";
import TagManager from "react-gtm-module";

import "./axios-config.js";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import Layout from "./hoc/Layout/Layout";
import Login from "./components/Login/Login";
import Levels from "./containers/Levels/Levels";
import Practice from "./containers/Practice/Practice";
import Spelling from "./containers/Spelling/Spelling";
import Forgot from "./components/Forgot/Forgot";
import Reading from "./containers/Reading/Reading";
import Home from "./containers/Home/Home";
import Admin from "./containers/Admin/Admin";
import AdminLevels from "./containers/Admin/AdminLevels/AdminLevels";
import AdminReadings from "./containers/Admin/AdminReadings/AdminReadings";
import AdminVideos from "./containers/Admin/AdminVideos/AdminVideos";
import EditLevelSentences from "./containers/Admin/AdminLevels/EditLevelSentences/EditLevelSentences.js";
import EditLevelWords from "./containers/Admin/AdminLevels/EditLevelWords/EditLevelWords.js";
//import Videos from './containers/Videos/Videos';
import AdminReadingsEdit from "./containers/Admin/AdminReadings/AdminReadingsEdit.js";
import AdminReadingsNew from "./containers/Admin/AdminReadings/AdminReadingsNew.js";

import "./App.scss";
import DotBombs from "./containers/Games/DotBombs/DotBombs.js";
import DontPop from "./containers/Games/DontPop/DontPop.js";
import { GlobalProvider } from "./providers/provider.js";
import AdminUsers from "./containers/Admin/AdminUsers/AdminUsers.js";

function App() {
  const tagManagerArgs = {
    gtmId: "GTM-TRNSGNP",
  };

  TagManager.initialize(tagManagerArgs);

  let user = null;
  //console.log(localStorage.getItem('user'));
  user = JSON.parse(localStorage.getItem("user"));
  let render = null;
  let loged = true;
  if (user) {
    let lastLog = new Date(user.lastLog);
    let now = new Date();
    let timeLapsed = (now.getTime() - lastLog.getTime()) / 3600000;
    const profile = JSON.parse(localStorage.getItem("profile"));
    //console.log(timeLapsed);
    console.log("profile", profile);
    if (timeLapsed < 1 && user.auth) {
      //si no ha pasado mas de una hora que se movio
      loged = true;
      const user = JSON.parse(localStorage.getItem("user"));
      user.lastLog = now;

      localStorage.setItem("user", JSON.stringify(user));
      render = (
        <>
          <Layout>
            <Routes>
              <Route path="/practice" element={<Practice />} />
              <Route path="/spelling" element={<Spelling />} />
              <Route path="/home" element={<Home />} />
              <Route path="/games/dot-bombs" element={<DotBombs />} />
              <Route path="/games/dont-pop" element={<DontPop />} />
              <Route path="/levels" act element={<Levels />} />
              {profile === 1 ? (
                <>
                  <Route path="/admin/videos" element={<AdminVideos />} />
                  <Route path="/admin/levels" element={<AdminLevels />} />
                  <Route
                    path="/admin/levels/sentences"
                    element={<EditLevelSentences />}
                  />
                  <Route
                    path="/admin/levels/words"
                    element={<EditLevelWords />}
                  />

                  <Route
                    path="/admin/readings/new"
                    element={<AdminReadingsNew />}
                  />

                  <Route
                    path="/admin/readings/edit"
                    element={<AdminReadingsEdit />}
                  />
                  <Route path="/admin/readings" element={<AdminReadings />} />
                  <Route path="/admin/users" element={<AdminUsers />} />

                </>
              ) : null}

              <Route path="/readings/:id" element={<Reading />} />
              <Route path="/*" element={<Navigate to="/levels" />} />
            </Routes>
          </Layout>
        </>
      );
    } else {
      loged = false;
    }
  } else {
    loged = false;
  }
  console.log(loged);
  if (!loged) {
    render = (
      <>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/*" element={<Navigate to="login" />}></Route>
        </Routes>
      </>
    );
  }

  return (
    <GlobalProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className="App">{render}</div>
        </BrowserRouter>
      </ThemeProvider>
    </GlobalProvider>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#211650",
    },
    secondary: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: ["Chewy", "cursive"].join(","),
    fontSize: 20,
  },
});

export default App;
