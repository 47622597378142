import React, { useEffect, useState } from "react";
import axios from "axios";

import Difficulty from "./Difficulty/Difficulty";
import Spinner from "../../../components/UI/Spinner/Spinner";

import "./LevelContainer.scss";
import { Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function LevelContainer() {
  const [levels, setLevels] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    let data = {};
    axios
      .post("/getDifficulties", data)
      .then((response) => {
        console.log(response);
        setLevels(response.data.difficulties);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let difficulties = null;
  if (levels) {
    difficulties = levels.map((item, index) => {
      return (
        <Difficulty
          idLevel={item.id}
          key={item.id}
          pose={item.img}
          enabled={item.enabled}
          name={item.name}
        />
      );
    });
  } else {
    difficulties = <Spinner />;
  }
  return <Stack width={isMobile ? "100%" : "69%"}>{difficulties}</Stack>;
}
