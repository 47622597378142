import { useState, useEffect } from "react";

import Sound from "../../../hoc/Sound/Sound";
import Doty from "../../../components/UI/Doty/Doty";
import H from "../../../components/UI/H/H";
import WordImg from "../../../components/UI/WordImg/WordImg";
import { Button } from "@mui/material";

import "./PracticeContainer.scss";
import { Stack } from "@mui/material";

const PracticeContainer = ({
  mode,
  dataSentence,
  answered,
  click,
  doty,
  streak,
}) => {
  const [options, setOptions] = useState([]);
  const [idSentence, setIdSentece] = useState(null);
  const [buildUpSentence, setBuildUpSentece] = useState([]);
  const [selectedWords, setSelectedWords] = useState([]);

  useEffect(() => {
    if (
      (mode !== "finished" || mode === "perfect") &&
      idSentence !== dataSentence.id
    ) {
      const options = [...dataSentence.options];
      options.forEach((item, i) => {
        options[i].selected = false;
        options[i].order = 0;
        options[i].id = i;
      });
      setOptions(options);
      setIdSentece(dataSentence.id);
      setBuildUpSentece([]);
    }
  }, [mode, dataSentence, idSentence]);

  const selectHandler = (index, item) => {
    console.log("HERE?", answered);
    if (answered === "") {
      const newOptions = [...options];
      let arrayOptions = [];
      let correct;
      if (mode === "buildUp") {
        arrayOptions = [...buildUpSentence];
        console.log("ITEM", item);
        const option_index = newOptions.findIndex(
          (option) => option.id === item.id
        );
        newOptions[option_index].selected = !newOptions[option_index].selected;

        if (newOptions[option_index].selected) {
          arrayOptions.push(item);
        } else {

          arrayOptions.splice(arrayOptions.findIndex((itm) => itm.id === item.id), 1);
        }
        const arrayWords = arrayOptions.map((item_option) => item_option.word)
        const text = arrayWords.join(" ");
        if (text.toUpperCase() === dataSentence.text.toUpperCase()) {
          correct = true;
        } else {
          correct = false;
        }
      } else {
        correct = item.correct;
        newOptions.forEach((item, i) => {
          newOptions[i].selected = false;
        });
        newOptions[index].selected = true;
      }
      console.log(newOptions);
      setOptions(newOptions);
      setBuildUpSentece(arrayOptions);
      click(correct);
    }
  };

  let content = null;
  let buttonClass = "";
  let sentence = dataSentence.text;
  let correct = "__";
  let deleted = false;
  let correctWord = "";
  if (
    mode === "complete" ||
    mode === "whatDoYouHear" ||
    mode === "whatDoYouHearSentence" ||
    mode === "guessImg" ||
    mode === "witchIs"
  ) {
    const newOptions = options.map((item, index) => {
      if (item.correct) {
        correctWord = item.word;
      }
      buttonClass = "";
      if (item.selected) {
        buttonClass = "PracticeContainer__selected";
      }
      if (item.selected && answered === "correct") {
        buttonClass = "PracticeContainer__correct";
        correct = item.word;
      } else if (item.selected && answered === "wrong") {
        buttonClass = "PracticeContainer__wrong";
      } else if (
        !item.selected &&
        (answered === "wrong" || answered === "correct")
      ) {
        if (item.correct) {
          buttonClass = "PracticeContainer__correct";
        } else {
          buttonClass = "PracticeContainer__default";
        }
      }
      let option = item.word;
      let optionClassSentence = "";
      if (mode === "whatDoYouHearSentence") {
        option = dataSentence.text.replace("__", item.word);
        optionClassSentence = "PracticeContainer__option-sentence";
      }

      let returnOption = (
        <div
          className={
            "PracticeContainer__option " +
            " " +
            optionClassSentence +
            " " +
            buttonClass
          }
          onClick={() => {
            selectHandler(index, item);
          }}
          key={index}
        >
          {option}
        </div>
      );
      if (!item.correct && !deleted && mode === "whatDoYouHearSentence") {
        returnOption = false;
        deleted = true;
      }
      if (mode === "guessImg") {
        returnOption = (
          <Sound
            click={() => {
              selectHandler(index, item);
            }}
            key={index}
            newclass={"PracticeContainer__option " + buttonClass}
            size
            src={item.img_sound}
            icon
          >
            {option}
          </Sound>
        );
      }
      if (mode === "witchIs") {
        returnOption = (
          <Sound
            click={() => {
              selectHandler(index, item);
            }}
            key={index}
            newclass={"PracticeContainer__option-img " + buttonClass}
            size
            src={item.img_sound}
            icon
          >
            <WordImg src={item.img} />
          </Sound>
        );
      }
      return returnOption;
    });
    if (answered === "correct") {
      sentence = dataSentence.text.replace("__", correct);
    }
    let soundContent;
    let titleText = "";
    if (
      mode === "whatDoYouHear" ||
      mode === "whatDoYouHearSentence" ||
      mode === "guessImg"
    ) {
      titleText = "What do you hear?";
      let srcSound = dataSentence.img_sound;
      if (mode === "whatDoYouHearSentence") {
        srcSound =
          "sentences/" +
          dataSentence.id +
          "." +
          dataSentence.sentence_extension;
      }
      if (mode !== "guessImg") {
        soundContent = (
          <Sound autoplay size src={srcSound} icon>
            <WordImg src={dataSentence.img} />
          </Sound>
        );
      } else {
        titleText = "What is this?";
        soundContent = <WordImg src={dataSentence.img} />;
      }
    } else {
      titleText = "Complete the sentence!";
      soundContent = (
        <Sound
          autoplay
          size
          src={
            "sentences/" +
            dataSentence.id +
            "." +
            dataSentence.sentence_extension
          }
          icon
        >
          <span className="PracticeContainer__text">{sentence}</span>
        </Sound>
      );
    }
    let classOptions = "PracticeContainer__options";
    if (mode === "whatDoYouHearSentence") {
      classOptions = "PracticeContainer__options-sentences";
    }
    let middleContent = (
      <div className="PracticeContainer__sentence">
        <Doty pose={doty} customClass="PracticeContainer__doty" />
        {soundContent}
      </div>
    );
    if (mode === "witchIs") {
      classOptions = "PracticeContainer__options-images";
      middleContent = null;
      titleText = "Which is: " + correctWord + "?";
    }

    content = (
      <div className="PracticeContainer__base animated slideInRight">
        <H type="3">{titleText}</H>
        {middleContent}
        <div className={classOptions}>{newOptions}</div>
      </div>
    );
  } else if (mode === "streak") {
    content = (
      <div className="PracticeContainer__base PracticeContainer__streak animated slideInUp">
        <H type="3">Yay!</H>
        <Doty pose="02" customClass="PracticeContainer__doty" />
        {streak} in a row!
      </div>
    );
  } else if (mode === "perfect" || mode === "finished" || mode === "gameover") {
    let text;
    let text2;
    let doty_pose = "02";
    if (mode === "perfect") {
      text = "Perfect!";
      text2 = "No mistakes!";
    } else if (mode === "gameover") {
      text = "Oh no!";
      text2 = "You ran out of hearts!";
      doty_pose = "05";
    } else {
      text = "Success!";
      text2 = "You completed the level!";
    }
    content = (
      <div className="PracticeContainer__base PracticeContainer__streak animated flipInY">
        <H type="3">{text}</H>
        <Doty pose={doty_pose} customClass="PracticeContainer__doty" />
        {text2}
      </div>
    );
  } else if (mode === "buildUp") {
    let completeSentence = null;
    console.log("options", options);
    const newOptions = options.map((item, index) => {
      buttonClass = "";
      const classOrder =
        "PracticeContainer__selected-build-order-" + item.order;

      if (item.selected) {
        buttonClass = "PracticeContainer__selected-build " + classOrder;
      }
      if (item.selected && answered === "correct") {
        buttonClass = "PracticeContainer__correct " + classOrder;
        correct = item.word;
      } else if (item.selected && answered === "wrong") {
        completeSentence = (
          <span className="PracticeContainer__correct-sentence">
            Correct: {dataSentence.text}
          </span>
        );
        if (item.correct) {
          buttonClass = "PracticeContainer__correct " + classOrder;
        } else {
          buttonClass = "PracticeContainer__wrong " + classOrder;
        }
      } else if (
        !item.selected &&
        (answered === "wrong" || answered === "correct")
      ) {
        if (item.correct) {
          buttonClass = "PracticeContainer__correct";
        } else {
          buttonClass = "PracticeContainer__default";
        }
      }
      let option = item.word;

      return (
        <Button
          display={item.selected}
          variant="contained"
          sx={{ order: item.order, transition: "order .2s ease", display: item.selected? "none" : "inline-block" }}
          className={"PracticeContainer__option-build animated zoomIn " + buttonClass}
          onClick={() => {
            selectHandler(index, item);
          }}
          key={index}
        >
          {option}
        </Button>
      );
    });
    const selectedOptions = buildUpSentence.map((word, index) => {
      return (
        <Button
          variant="contained"
          className={"PracticeContainer__option-build animated slideInRight"}
          onClick={() => {
            selectHandler(index, word);
          }}
          key={index}
        >
          {word.word}
        </Button>
      );
    });
    content = (
      <div className="PracticeContainer__base animated slideInRight">
        <H type="3">Build up the sentence!</H>
        <div className="PracticeContainer__sentence">
          <Doty pose={doty} customClass="PracticeContainer__doty" />
          <Sound
            autoplay
            size
            src={
              "sentences/" +
              dataSentence.id +
              "." +
              dataSentence.sentence_extension
            }
            icon
          >
            <WordImg src={dataSentence.img} />
          </Sound>
        </div>
        {completeSentence}
        <Stack
          sx={{ transition: "all .2s" }}
          direction={"row"}
          flexWrap={"wrap"}
          gap={1}
          className="PracticeContainer__options-build"
        >
          {selectedOptions}
          <div className="PracticeContainer__build-block"></div>
          {newOptions}
        </Stack>
      </div>
    );
  }
  return <div className="PracticeContainer">{content}</div>;
};

export default PracticeContainer;
