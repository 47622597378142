import React, { Component } from 'react';


import './AdminVideos.scss';

class AdminVideos extends Component {

  render(){

    return(
      <div className="AdminVideos">

      </div>
    );
  }
}

export default AdminVideos;
