import React, { Component } from "react";
import axios from "axios";

import LevelWord from "./LevelWord/LevelWord";

import "./LevelSection.scss";

class LevelSection extends Component {
  state = {
    levels: null,
    color: [
      "pink",
      "blue",
      "orangered",
      "opal",
      "orange",
      "pale_green",
      "yellow",
      "green",
      "pale_blue",
    ],
    animation: [
      "fade-up",
      "fade-up-left",
      "fade-left",
      "fade-down-left",
      "fade-down",
      "fade-down-right",
      "fade-right",
      "fade-up-right",
    ],
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    let data = {
      id: this.props.id,
      user: user.id,
    };
    if (data.id) {
      axios
        .post("/getLevelsById", data)
        .then((response) => {
          console.log(response.data.levels);
          if (response.data.levels.length > 0) {
            this.setState({ levels: response.data.levels });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    let levels = null;
    if (this.state.levels) {
      levels = this.state.levels.map((item, index) => {
        let colorValue = index;
        while (colorValue > 8) {
          colorValue = colorValue - 9;
        }
        let animation = colorValue;
        while (animation > 7) {
          animation = animation - 8;
        }
        let src = item.src;
        if (item.on_construction === 1) {
          src = "under_construction.png";
        }
        return (
          <LevelWord
            id={item.id}
            animation={this.state.animation[animation]}
            key={item.id}
            name={item.name}
            color={this.state.color[colorValue]}
            on_construction={item.on_construction}
            src={src}
            available={item.available}
            levels_left={item.levels_left}
            progress={item.progress}
          />
        );
      });
    }
    return (
      <div className="LevelSection">
        <span  className="LevelSection__title">
          {this.props.name}
        </span>
        <div className="LevelSection__container">{levels}</div>
      </div>
    );
  }
}

export default LevelSection;
