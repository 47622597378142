import { Check, DeleteForever, Undo } from "@mui/icons-material";

import {
  Box,
  Container,
  Typography,
  Stack,
  TextField,
  Card,
  Button,
} from "@mui/material";
import { useState } from "react";

import Sound from "../../../hoc/Sound/Sound";
import { BASE_URL_IMAGES } from "../../../constants";

export default function AdminReadingsEditor({ reading, saveReading }) {
  const [title, setTitle] = useState(reading?.title);
  const [text, setText] = useState(reading?.text);
  const [readingAudio, setReadingAudio] = useState(null);

  return reading ? (
    <Container sx={{ padding: "4rem" }}>
      <Typography variant="h3" gutterBottom>
        {reading.id_reading > 0 ? "Edit reading" : "New Reading"}
      </Typography>
      <Stack direction={"row"} justifyContent={"center"} gap={2}>
        <Stack width={"70%"} gap={2}>
          <Stack direction="row" gap={1}>
            <TextField
              maxLength={400}
              aria-label="Body"
              fullWidth
              label="title"
              variant="outlined"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <Button
              height="2rem"
              variant={"contained"}
              disabled={title === reading.title}
              click={() => {
                setTitle(reading.title);
              }}
            >
              <Undo />
            </Button>
          </Stack>
          <Stack direction="row" gap={1}>
            <TextField
              variant="outlined"
              maxLength={400}
              label="Body"
              multiline
              value={text}
              fullWidth
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
            <Button
              height="2rem"
              variant={"contained"}
              disabled={text === reading.text}
              click={() => {
                setText(reading.text);
              }}
            >
              <Undo />
            </Button>
          </Stack>
        </Stack>
        <Card variant="outlined" sx={{ padding: "2rem" }}>
          <Stack alignItems="center" justifyContent="center">
            <Sound icon size position="rigth" src={reading.src}>
              <img
                style={{ width: "6rem", height: "6rem" }}
                src={BASE_URL_IMAGES + "/words/others/bocina.png"}
                alt={"speaker"}
              />
            </Sound>
            <Stack gap={2}>
              <Button variant="contained" component="label">
                {readingAudio ? (
                  <>
                    Uploaded <Check />
                  </>
                ) : (
                  "Change audio"
                )}
                <input
                  hidden
                  accept="audio/*"
                  type="file"
                  onChange={(event) => {
                    setReadingAudio(event.target.files[0]);
                  }}
                />
              </Button>
              {readingAudio && (
                <Button
                  onClick={() => {
                    setReadingAudio(null);
                  }}
                >
                  <DeleteForever />
                </Button>
              )}
            </Stack>
          </Stack>
        </Card>
      </Stack>
      <Stack direction={"row"} justifyContent={"center"} gap={2}>
        <Button
          height="2rem"
          variant={"outlined"}
          onClick={() => {
            window.location.replace("/admin/readings");
          }}
        >
          Cancel
        </Button>
        <Button
          height="2rem"
          variant={"contained"}
          disabled={
            text === reading.text &&
            title === reading.title &&
            readingAudio === null
          }
          onClick={() => {
            saveReading(title, text, readingAudio);
          }}
        >
          Save
        </Button>
      </Stack>
    </Container>
  ) : (
    <Box>Loading</Box>
  );
}
