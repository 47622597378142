import { useState, useEffect } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import axios from "axios";
import Card from "../../../../components/UI/Card/Card";
import Table from "../../../../components/UI/Table/Table";
import H from "../../../../components/UI/H/H";
import Button from "../../../../components/UI/Button/Button";
import Dialog from "../../../../components/UI/Dialog/Dialog";
import {
  Edit,
  Visibility,
  VisibilityOff,
  Undo,
  Add,
} from "@mui/icons-material";
import { BASE_URL_IMAGES } from "../../../../constants";
import { ButtonGroup } from "@mui/material";
import "./EditLevelSentences.scss";
import NewEditFormSentence from "./NewEditFormSentence/NewEditFormSentence";

const EditLevelSentences = () => {
  const [sentences, setSentences] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sentenceForm, setSentenceForm] = useState({ show: false });
  const [dialog, setDialog] = useState({});

  const title = searchParams.get("title")
    ? searchParams.get("title").replace("+", " ")
    : "";
  const id = searchParams.get("id") ? searchParams.get("id") : 0;

  useEffect(() => {
    getSentencesByLevel()
  }, []);

  const getSentencesByLevel = () => { 
    axios
    .get("/sentences/get-by-level/" + id)
    .then(({ data }) => {
      if (data.length > 0) {
        const sentencesArray = data.map((sentence) => {
          return {
            key: sentence.id,
            id: sentence.id,
            sentence: sentence.text,
            m_word: sentence.m_word,
            src_img: sentence.img,
            src_sound: sentence.img_sound,
            enabled: sentence.enabled,
            sentence_extension : sentence.sentence_extension,
            no_img : sentence.no_img
          };
        });
        setSentences(sentencesArray);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const closeDialogHandler = () => {
    setDialog({show : false})
  }

  const enableSentenceRequestHandler = (id, enabled) => {
    const data = {
      id: id,
      enabled: enabled,
    };

    axios
      .put("/sentence/enable-disable", data)
      .then(() => {
        getSentencesByLevel();
        setDialog({
          show: true,
          type: "success",
          accept_text: "great!",
          accept: closeDialogHandler,
          text: "Success!",
        });
      })
      .catch((error) => {
        console.log(error);
        setDialog({
          show: true,
          type: "error",
          accept_text: "okay :(",
          accept: closeDialogHandler,
          text: "Something went wrong, please try again",
        });
      });
  };

  const enableSentenceHandler = (sentence) => {
    console.log(sentence);
    const text = sentence.enabled === 1 ? "DISABLE" : "ENABLE";
    const enabled = sentence.enabled === 1 ? 0 : 1;
    setDialog({
      text: "Are you sure you want to " + text + " this sentence?",
      type: "warning",
      accept: () => {
        enableSentenceRequestHandler(sentence.id, enabled);
      },
      accept_text: "yes!",
      cancel_text: "nope!",
      show: true,
      cancel: closeDialogHandler,
    });
  };

  const newSentenceHandler = () => {
    setSentenceForm({
      show: true,
      edit: false,
      idLevel: id,
      levelName: title,
      sentenceEdit: "",
      missingWordEdit: "",
      imageSrcEdit: "",
      sentenceId: 0,
      imageSoundSrcEdit: "",
      cancel: closeEditSentenceHandler,
      onOk: getSentencesByLevel,
    });
  };

  const closeEditSentenceHandler = () => {
    setSentenceForm({
      show: false,
    });
  };

  const editSentenceHandler = (sentence) => {
    console.log(sentence);

    setSentenceForm({
      show: true,
      edit: true,
      idLevel: id,
      levelName: title,
      sentenceEdit: sentence.sentence,
      missingWordEdit: sentence.m_word,
      imageSrcEdit: sentence.src_img,
      sentenceId: sentence.id,
      imageSoundSrcEdit: sentence.src_sound,
      cancel: closeEditSentenceHandler,
      onOk: getSentencesByLevel,
      sentence_src : sentence.sentence_extension
    });
  };

  const columns = [
    {
      field: "sentence",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Sentence",
      width: 200,
      height: 100,
    },
    {
      field: "m_word",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Missing Word",
      width: 100,
      height: 100,
    },
    {
      field: "image",
      headerName: "Image",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      height: 100,
      renderCell: ({ row }) => {
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "5rem", height: "5rem", marginRight: "2rem" }}
              src={BASE_URL_IMAGES + "/words/" + row.src_img}
              alt={row.src_img}
            />
            {row.name}
          </span>
        );
      },
    },
    {
      field: "enabled",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Enabled",
      width: 50,
      height: 100,
      renderCell: ({ row }) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              click={() => {
                enableSentenceHandler(row);
              }}
            >
              {row.enabled === 1 ? <Visibility /> : <VisibilityOff />}
            </Button>
          </div>
        );
      },
    },
    {
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      width: 20,
      height: 100,
      renderCell: ({ row }) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              click={() => {
                editSentenceHandler(row);
              }}
            >
              <Edit />
            </Button>
          </div>
        );
      },
    },
  ];
  console.log("searchParams", searchParams.get("id"));
  return (
    <>
      <div className="EditLevelSentences">
        <H type="1">Sentences</H>
        <Card>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <H type="3">{title}</H>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" style={{ position: "relative" }}>
                <NavLink
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  to={"/admin/levels"}
                ></NavLink>
                Back to Levels <Undo />
              </Button>
              <ButtonGroup sx={{ margin: "0 2rem" }}>
                <Button variant="contained">Sentences</Button>

                <Button variant="outlined" style={{ position: "relative" }}>
                  <NavLink
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    to={"/admin/levels/words?id=" + id + "&title=" + title}
                  ></NavLink>
                  Words
                </Button>
              </ButtonGroup>
              <Button variant="contained" click={newSentenceHandler}>
                New <Add />
              </Button>
            </div>
            <br />
            <Table rows={sentences} columns={columns} pageSize={5} />
          </div>
        </Card>
      </div>
      <NewEditFormSentence {...sentenceForm} />
      <Dialog {...dialog} />
    </>
  );
};

export default EditLevelSentences;
