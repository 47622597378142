import React from "react";

import LevelContainer from "./LevelContainer/LevelContainer";
import InteractiveColumn from "./InteractiveColumn/InteractiveColumn";

import "./Levels.scss";
import { Container, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const Levels = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container
    overflow="hidden"
      className="Levels "
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: isMobile ? "column-reverse" : "row",
      }}
    >
      <LevelContainer />
      <InteractiveColumn />
    </Container>
  );
};

export default Levels;
