import React, { useEffect, useState } from "react";
import axios from "axios";

import H from "../../../components/UI/H/H";
import WordImg from "../../../components/UI/WordImg/WordImg";
import Doty from "../../../components/UI/Doty/Doty";
import Option from "../../../components/UI/Option/Option";
import Balloon from "../../../assets/images/PopIt/balloon.png";
import Explotion from "../../../assets/images/PopIt/explotion.gif";
import Win from "../../../assets/images/PopIt/win.gif";
import ButtonUp from "../../../assets/images/PopIt/ButtonUp.png";
import ButtonDown from "../../../assets/images/PopIt/ButtonDown.png";
import { getDontPopDataService } from "../../../services/games.service";

import "./DontPop.scss";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import GoBack from "../../../components/UI/GoBack/GoBack";
import { useTheme } from "@emotion/react";

export default function DontPop() {
  const [place, setPlace] = useState("start");
  const [pose, setPose] = useState(12);
  const [game, setGame] = useState(null);
  const [options, setOptions] = useState(null);
  const [size, setSize] = useState(1.0);
  const [text, setText] = useState("Complete all words before you explode!");
  const [index, setIndex] = useState(-1);
  const [data, setData] = useState([]);
  const [dotyPosition, setDotyPosition] = useState("50%");
  const [btn1, setBtn1] = useState(ButtonUp);
  const [btn2, setBtn2] = useState(ButtonUp);
  const user = JSON.parse(localStorage.getItem("user"));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    const getData = async () => {
      if (user?.id > 0) {
        const wordsData = await getDontPopDataService(user.id);
        if (wordsData.length > 0) {
          setData(wordsData);
        }
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (size >= 15) {
        endgameHandler(false);
      } else {
        if (place === "game") {
          setSize((size) => size + 0.1);
        }
      }
    }, 100);
    return () => clearInterval(interval);
  }, [place, size]);

  const setGameHandler = () => {
    if (data.length >= 2) {
      let found = false;
      let i = 0;
      const data_handler = [...data];
      while (i < data_handler.length && found === false) {
        if (data_handler[i].answered === false) {
          found = true;
        }
        i++;
      }

      if (found) {
        let newIndex = index + 1;
        if (newIndex === data_handler.length) {
          setIndex(0);
          newIndex = 0;
        }
        let randomIndex = Math.floor(Math.random() * data_handler.length);
        let position = Math.round(Math.random() * 1);

        let newArray = [];
        if (randomIndex === newIndex) {
          if (randomIndex !== 0) {
            randomIndex = randomIndex - 1;
          } else {
            randomIndex++;
          }
        }
        if (position === 1) {
          newArray.push(
            {
              text: data_handler[randomIndex].title,
              id: data_handler[randomIndex].id,
            },
            {
              text: data_handler[newIndex].title,
              id: data_handler[newIndex].id,
            }
          );
        } else {
          newArray.push(
            {
              text: data_handler[newIndex].title,
              id: data_handler[newIndex].id,
            },
            {
              text: data_handler[randomIndex].title,
              id: data_handler[randomIndex].id,
            }
          );
        }
        setData(data_handler);
        setIndex(newIndex);
        setOptions(newArray);
      } else {
        endgameHandler(true);
      }
    }
  };

  const endgameHandler = (won) => {
    if (won) {
      setPlace("endgame");
      setText("You win!");
      setPose("12");
    } else {
      setPlace("endgame");
      setText("You explode!");
      setPose("05");
    }
    const new_data = [...data];
    for (const item of new_data) {
      item.answered = false;
    }
    setData(new_data);
  };

  const answerHandler = (answer) => {
    if (answer === data[index].id) {
      setPose("02");
      const new_data = [...data];
      new_data[index].answered = true;
      let new_size = size - 4;
      if (new_size < 0) {
        new_size = 0;
      }
      setSize(new_size);
      setData(new_data);
    } else {
      setSize(size + 6);
      setPose("05");
    }
    if (size >= 15) {
      endgameHandler(false);
    } else {
      setGameHandler();
    }
  };

  const optionHandler = (type) => {
    switch (type) {
      case "start":
        setText("Complete all words before you explode!");
        setPose("14");
        setSize(1);
        setIndex(-1);
        break;
      case "game":
        setPose("14");
        setSize(1);
        setIndex(-1);
        setGameHandler();
        break;
      default:
    }
    setPlace(type);
  };

  let bottom_options = null;
  let content = null;
  let background = null;
  switch (place) {
    case "start":
      content = <Typography variant="h4">{text}</Typography>;
      bottom_options = (
        <Button
          onClick={() => {
            optionHandler("game");
          }}
          variant="contained"
          fullWidth
          sx={{ margin: "4rem" }}
        >
          <Typography variant="h3">Start</Typography>
        </Button>
      );
      break;
    case "endgame":
      content = <Typography variant="h4">{text}</Typography>;
      bottom_options = (
        <Button
          onClick={() => {
            optionHandler("start");
          }}
          variant="contained"
          fullWidth
          sx={{ margin: "4rem" }}
        >
          <Typography variant="h3">restart</Typography>
        </Button>
      );
      if (size >= 15) {
        background = (
          <img
            src={Explotion}
            alt=""
            style={{
              transition: "all .2s",
              transform: "translateX(-50%)",
              zIndex: "1",
              width: "60rem",
              transformOrigin: "bottom center",
              position: "absolute",
              bottom: "25rem",
              left: "48.5%",
            }}
          />
        );
      } else {
        background = (
          <img
            src={Win}
            alt=""
            style={{
              transition: "all .2s",
              transform: "translateX(-50%)",
              zIndex: "1",
              width: "60rem",
              transformOrigin: "bottom center",
              position: "absolute",
              bottom: "15rem",
              left: "50.5%",
            }}
          />
        );
      }
      break;
    case "game":
      content = (
        <>
          <Typography variant="h4">What is this?</Typography>
          <WordImg
            key={data[index].src}
            customClass="animated slideInDown"
            src={data[index].src}
            size="small"
          />
        </>
      );
      bottom_options = (
        <Stack
          onMouseLeave={() => {
            setDotyPosition("50%");
            setBtn2(ButtonUp);
            setBtn1(ButtonUp);
          }}
          direction={"row"}
          width={"100%"}
          padding={"4rem"}
          position={"relative"}
        >
          <Stack
            width={"50%"}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={() => {
              answerHandler(options[0].id);
            }}
            onMouseEnter={() => {
              setDotyPosition("25%");
              setBtn2(ButtonUp);
              setBtn1(ButtonDown);
              setPose("06");
            }}
          >
            <Stack
              alignItems={"center"}
              sx={{
                backgroundColor: "rgb(224,224,244)",
                cursor: "pointer",
                position: "relative",
                width: "60%",
                height: "20rem",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "8rem",
                  position: "absolute",
                  top: "-1rem",
                  backgroundColor: "#C9C9DB",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  width={isMobile ? "80%" : "120%"}
                  src={btn1}
                  title="button"
                  alt="button"
                  style={{ position: "absolute", top: "-2rem" }}
                />
              </Box>
              <Box position={"absolute"} top={"9rem"}>
                <Typography variant="h4">{options[0].text}</Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack
            onClick={() => {
              answerHandler(options[1].id);
            }}
            width={"50%"}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            onMouseEnter={() => {
              setDotyPosition("75%");
              setBtn2(ButtonDown);
              setBtn1(ButtonUp);
              setPose("06");
            }}
          >
            <Stack
              alignItems={"center"}
              sx={{
                backgroundColor: "rgb(224,224,244)",
                cursor: "pointer",
                position: "relative",
                width: "60%",
                height: "20rem",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "8rem",
                  position: "absolute",
                  top: "-1rem",
                  backgroundColor: "#C9C9DB",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  width={isMobile ? "80%" : "120%"}
                  src={btn2}
                  title="button"
                  alt="button"
                  style={{ position: "absolute", top: "-2rem" }}
                />
              </Box>

              <Box position={"absolute"} top={"9rem"}>
                <Typography variant="h4">{options[1].text}</Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      );
      background = (
        <img
          src={Balloon}
          alt=""
          style={{
            transition: "all .2s",
            transform: `scale(${size}) `,
            width: "2rem",
            transformOrigin: "bottom center",
            position: "absolute",
            bottom: "30rem",
            left: "48.5%",
          }}
        />
      );
      break;

    default:
  }

  console.log("data", data);
  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "60rem",
            height: "95%",
            position: "relative",
            backgroundColor: "#e6e6fc",
          }}
        >
          {background}
          <Box
            sx={{
              zIndex: 1,
              position: "absolute",
              top: "12rem",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                place === "start" ? "#e6e6fc" : "rgba(245,245,245, .7)",
              padding: "1rem",
              borderRadius: "1rem",
            }}
          >
            {content}
          </Box>
          <Box
            sx={{
              zIndex: 1,
              position: "absolute",
              bottom: "15rem",
              left: dotyPosition,
              transform: "translateX(-50%)",
              transition: "all .4s",
            }}
          >
            <Doty pose={pose} size="medium" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "0rem",
              width: "100%",
              height: "30rem",
              backgroundColor: "grey",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                zIndex: 1,
                position: "absolute",
                bottom: "0rem",
                width: "100%",
                height: "25rem",
              }}
            >
              {bottom_options}
            </Stack>
          </Box>
        </Box>
        <GoBack to="/levels" />
      </Container>
    </>
  );
}
