import React from 'react';

import SideNav from '../../components/UI/SideNav/SideNav';
import InteractiveColumn from '../../containers/Levels/InteractiveColumn/InteractiveColumn';
import { Stack } from '@mui/material';

const layout = (props) => {
    return (
        <>
            
            <main>
                {props.children}
            </main>
        </>
        );
}

export default layout;
