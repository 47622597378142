import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import "./Practice.scss";

import LoadBar from "../../components/UI/LoadBar/LoadBar";
import ConfirmButtons from "../../components/UI/ConfirmButtons/ConfirmButtons";
import Doty from "../../components/UI/Doty/Doty";
import CorrectSound from "../../assets/sounds/answers/correct.wav";
import WrongSound from "../../assets/sounds/answers/wrong.wav";
import Spinner from "../../components/UI/Spinner/Spinner";
import PracticeContainer from "./PracticeContainer/PracticeContainer";
import { useEffect } from "react";
import { sendProgressService } from "../../services/sentences.service";
import { Container, Stack, Typography } from "@mui/material";
import Heart from "../../assets/images/heart.png";

export default function Practice() {
  const [progress, setProgress] = useState(0);
  const [streak, setStreak] = useState(0);

  const [btnName2, setBtnName2] = useState("confirm");
  const [btnClass2, setBtnClass2] = useState("default");
  const [arraySentences, setArraySentences] = useState([]);
  const [doty, setDoty] = useState(null);
  const [sentence, setSentence] = useState("");
  const [indexSentence, setIndexSentence] = useState(0);
  const [noSentences, setNoSentences] = useState(false);
  const [mode, setMode] = useState("complete");
  const [options, setOptions] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [answerState, setAnswerState] = useState("");
  const [totalSentences, setTotalSentences] = useState(0);
  const [answered, setAnswered] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [lifes, setLifes] = useState(4);

  const id = searchParams.get("id") ? searchParams.get("id") : 0;

  const wrong = new Audio(WrongSound);
  const correct = new Audio(CorrectSound);
  const btnName1 = "exit";
  const btnClass1 = "default";

  useEffect(() => {
    axios
      .get("/sentences/sentences-practice/" + id)
      .then((response) => {
        //console.log("RESPONSE: ",response.data);
        if (response.data.length > 0) {
          setArraySentences(response.data);
          setDoty("07");
          setTotalSentences(response.data.length);
          setMode(response.data[indexSentence].mode);
        } else {
          setNoSentences(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    if (lifes <= 0) {
      levelFinishedHandler();
    }
  }, [lifes]);

  const playSoundHandler = (sound) => {
    if (sound === "correct") {
      correct.play();
    } else {
      wrong.play();
    }
  };

  const isSelectedHandler = (answer) => {
    setAnswer(answer);
    setBtnClass2("primary");
  };

  const goToLevelsHandler = () => {
    window.location.replace("/levels");
  };

  const confirmSelectedHandler = () => {
    if (answerState === "") {
      if (answer) {
        const newStreak = streak + 1;
        const newAnswered = answered + 1;
        const newArraySentences = [...arraySentences];
        newArraySentences[indexSentence].answered = true;

        const progress = Math.floor((newAnswered / totalSentences) * 100);

        setAnswerState("correct");
        setDoty("02");
        setStreak(newStreak);
        setProgress(progress);
        setAnswered(newAnswered);
        setArraySentences(newArraySentences);

        playSoundHandler("correct");
      } else {
        const newArraySentences = [...arraySentences];

        newArraySentences[indexSentence].times_wrong =
          newArraySentences[indexSentence].times_wrong + 1;
        setArraySentences(newArraySentences);

        playSoundHandler("wrong");

        setAnswerState("wrong");

        setDoty("05");
        setStreak(0);
        setLifes(lifes - 1);
      }
      setBtnName2("continue");
    } else {
      whatsNextHandler();
    }
  };

  const nextSentenceHandler = () => {
    const newArraySentences = [...arraySentences];
    let index = indexSentence + 1;
    let leave = false;
    let count = 0;
    while (!leave) {
      if (index === newArraySentences.length) {
        index = 0;
      } else {
        if (!newArraySentences[index].answered) {
          leave = true;
        } else {
          index++;
        }
      }
      count++;
      if (count > newArraySentences.length * 2) {
        leave = true;
      }
    }
    setIndexSentence(index);
    setDoty("07");
    setAnswer(null);
    setAnswerState("");
    setMode(newArraySentences[index].mode);
    setBtnName2("confirm");
  };

  const streakCongratzHandler = () => {
    setMode("streak");
  };

  const levelFinishedHandler = () => {
    let mode;
    if (totalSentences === streak) {
      mode = "perfect";
    } else if (lifes === 0) {
      mode = "gameover";
      setBtnName2("Try again");
    } else {
      mode = "finished";
    }
    setMode(mode);
    sendProgress();
  };

  const sendProgress = () => {
    const sentences = [];
    for (const sentence of arraySentences) {
      if (sentence.answered || sentence.times_wrong > 0) {
        sentences.push({
          id_sentence: sentence.id,
          answered: sentence.answered,
          times_wrong: sentence.times_wrong,
        });
      }
    }
    let user = JSON.parse(localStorage.getItem("user"));
    const level_id = id;

    console.log(sentences, level_id, user);
    sendProgressService(sentences, level_id, user.id);
  };

  const whatsNextHandler = () => {
    if (mode === "finished" || mode === "perfect") {
      goToLevelsHandler();
    } else if (mode === "gameover") {
      window.location.reload();
    } else {
      if (mode !== "streak") {
        if (answered >= totalSentences) {
          levelFinishedHandler();
        } else if (streak >= 5) {
          let newStreak = (streak + "").split("");

          newStreak = newStreak[newStreak.length - 1];
          if (newStreak === "5" || newStreak === "0") {
            streakCongratzHandler();
          } else {
            nextSentenceHandler();
          }
        } else {
          nextSentenceHandler();
        }
      } else {
        nextSentenceHandler();
      }
    }
  };

  let content = <Spinner />;
  if (arraySentences.length > 0) {
    content = (
      <>
        <Stack direction={"row"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ marginLeft: "2rem" }}
          >
            <img src={Heart} alt="Hearts" height={30} width={30} className={lifes <= 2 && "animated pulse infinite"} />
            <Typography>{lifes}</Typography>
          </Stack>
          <LoadBar progress={progress} streak={streak} />
        </Stack>
        <PracticeContainer
          doty={doty}
          dataSentence={arraySentences[indexSentence]}
          mode={mode}
          click={(selected) => {
            isSelectedHandler(selected);
          }}
          answered={answerState}
          streak={streak}
        />
        <ConfirmButtons
          name1={btnName1}
          name2={btnName2}
          btnClass1={btnClass1}
          btnClass2={btnClass2}
          click1={goToLevelsHandler}
          click2={confirmSelectedHandler}
        />
      </>
    );
  } else if (noSentences) {
    content = (
      <div>
        <Doty pose="05" />
        Something went wrong, please try again later.
      </div>
    );
  }
  return <Container className="Practice">{content}</Container>;
}
