import React, { useEffect, useState } from "react";
import Doty from "../../../components/UI/Doty/Doty";
import { Box, Card, Stack, Typography, useMediaQuery } from "@mui/material";
import { getRankingStudentsService } from "../../../services/progress.service";
import { BASE_URL_IMAGES } from "../../../constants";
import { Book } from "@mui/icons-material";
import GoldCup from "../../../assets/images/cups/gold_cup.png";
import SilverCup from "../../../assets/images/cups/silver_cup.png";
import bronzeCup from "../../../assets/images/cups/bronze_cup.png";
import { useTheme } from "@emotion/react";

export default function TopStudents() {
  const [ranking, setRanking] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    const getRankingStreaks = async () => {
      const response = await getRankingStudentsService();
      if (response.length > 0) {
        setRanking(response);
      }
    };
    getRankingStreaks();
  }, []);
  const cupWidth = isMobile ? "6rem" : "3rem";
  const photoSize = isMobile ? "7rem" : "5rem";
  return (
    <Card
      sx={{
        gap: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem",
        width: isMobile ? "50rem" : "30rem",
        boxShadow: "",
        position: "relative",
        resize: "none",
        height: "100%",
        overflow: "hidden auto",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <Doty pose="17" size={isMobile ? "tiny" : "mini"} />
        <Typography variant={isMobile ? "h3" : "h5"}>
          Top students this week!
        </Typography>
      </Stack>
      <Stack gap={1}>
        {ranking.map((item, index) => {
          return (
            <Stack
              key={item.id}
              direction={"row"}
              alignItems={"center"}
              gap={1}
            >
              {index === 0 && (
                <img src={GoldCup} alt="gold" style={{ width: cupWidth }} />
              )}
              {index === 1 && (
                <img src={SilverCup} alt="silver" style={{ width: cupWidth }} />
              )}
              {index === 2 && (
                <img src={bronzeCup} alt="bronze" style={{ width: cupWidth }} />
              )}
              {index > 2 && <Box width={"3rem"} />}

              <Typography variant={isMobile ? "h4" : "h6"}>
                {index + 1}
              </Typography>
              {item.profile_pic ? (
                <Box
                  width={photoSize}
                  height={photoSize}
                  borderRadius={"50%"}
                  overflow={"hidden"}
                >
                  <img
                    src={`${BASE_URL_IMAGES}/users/${item.profile_pic}`}
                    alt={item.name}
                    title={item.name}
                    style={{ width: photoSize }}
                  />
                </Box>
              ) : (
                <Doty size="mini" pose="03" />
              )}
              <Typography variant={isMobile ? "h4" : "h6"}>
                {item.name} {item.last_name}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
}
