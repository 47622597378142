import React, { useEffect, useState } from "react";

import QuickLesson from "./QuickLesson/QuickLesson";
import Streak from "./Streak/Streak";
import Doty from "../../../components/UI/Doty/Doty";
import Dialog from "../../../components/UI/Dialog/Dialog";
import {
  Restore,
  Favorite,
  LocationOn,
  AutoStories,
  EmojiEvents,
  Whatshot,
  MenuBook,
  School,
  SportsEsports,
  OndemandVideo,
  Edit,
} from "@mui/icons-material";

import "./InteractiveColumn.scss";
import DailyProgress from "./DailyProgress/DailyProgress";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Card,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import StreakTop from "./Streak/StreakTop";
import TopStudents from "./TopStudents";
import Reading from "../../Reading/Reading";
import ReadingsList from "./ReadingsList";
import GamesList from "./GamesList";
import { updateProfilePictureService } from "../../../services/user.service";
import { ADMIN_PROFILE, BASE_URL_IMAGES } from "../../../constants";
import { useTheme } from "@emotion/react";
const user = JSON.parse(localStorage.getItem("user"));
export default function InteractiveColumn() {
  const [hover, setHover] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [hoverPhoto, setHoverPhoto] = useState(null);
  const [userHover, setUserHover] = useState(false);
  const [userSettingsHover, setUserSettingsHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAdmin = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [dialog, setDialog] = useState({});

  useEffect(() => {
    console.log("profile_pic", user.profile_pic);
    if (user.id > 0 && user.profile_pic !== "" && user.profile_pic !== null) {
      console.log("sets", BASE_URL_IMAGES + "/users/" + user.profile_pic);
      setProfilePhoto(BASE_URL_IMAGES + "/users/" + user.profile_pic);
    }
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!hover) {
        if (currentSection === menus.length - 1) {
          setCurrentSection(0);
        } else {
          setCurrentSection((currentSection) => currentSection + 1);
        }
      }
    }, 8000);
    return () => clearInterval(interval);
  }, [hover, currentSection]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (link) => {
    setAnchorEl(null);
    window.location.replace(link);
  };

  const closeDialogHandler = () => {
    setDialog({ show: false });
  };

  const logOutHandler = () => {
    setDialog({
      text: "Are you sure you want to log out?",
      type: "warning",
      accept: () => {
        localStorage.removeItem("user");
        window.location.replace("/login");
      },
      accept_text: "yes!",
      cancel_text: "nope!",
      show: true,
      cancel: closeDialogHandler,
    });
  };
  const setProfilePhotoHandler = (file) => {
    if (user?.id > 0) {
      console.log("updates");
      setProfilePhoto(URL.createObjectURL(file));
      updateProfilePictureService(user.id, file);
      user.profile_pic = `${String(user.id)}.${file.name.split(".")[1]}`;
      localStorage.setItem("user", JSON.stringify(user));
    }
  };

  const position = isMobile ? 50 : 30;
  const marginPosition = isMobile ? 75 : 45;
  const coming_soon = (
    <Card
      sx={{
        width: isMobile ? "50rem" : "30rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Doty pose="03" size="small" />
      <Typography variant="h4">Coming soon...</Typography>
    </Card>
  );
  const menus = [
    { title: "My progress", icon: <School /> },
    { title: "Audio books", icon: <OndemandVideo /> },
    { title: "Readings", icon: <MenuBook /> },
    { title: "Top students", icon: <EmojiEvents /> },
    { title: "Top streaks", icon: <Whatshot /> },
    { title: "Games", icon: <SportsEsports /> },
  ];
  console.log("profilePhoto", profilePhoto);
  return (
    <>
      <Stack
        width={isMobile ? "100%" : "29%"}
        height={isMobile ? "auto" : "100vh"}
        position={"relative"}
        className="InteractiveColumn"
        overflow={"hidden auto"}
        scrollbarWidth={"none"}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <Box
          position={isMobile ? "relative" : "fixed"}
          paddingLeft={"1rem"}
          paddingTop={"2rem"}
          overflow={"hidden"}
          width={isMobile ? "100%" : "30rem"}
          height={"100vh"}
        >
          <Stack
            position={"relative"}
            display={"flex"}
            gap={1}
            flexDirection={"column"}
            alignItems={"center"}
            transition="all .2s"
          >
            <Stack
              width={isMobile ? "100%" : "auto"}
              direction={isMobile ? "row" : "column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={2}
                onMouseEnter={() => setUserHover(true)}
                onMouseLeave={() => setUserHover(false)}
              >
                <Box
                  className="SideNav__doty-container"
                  style={{
                    display: "flex",
                    width: "12rem",
                    height: "12rem",
                    position: "relative",
                  }}
                  onMouseEnter={() => setHoverPhoto(true)}
                  onMouseLeave={() => setHoverPhoto(false)}
                >
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#ccc",
                      opacity: hoverPhoto ? 0.8 : 0,
                      zIndex: 1,
                    }}
                  >
                    <Button
                      component="label"
                      height="100%"
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <input
                        style={{ height: "100%", width: "100%" }}
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(event) => {
                          setProfilePhotoHandler(event.target.files[0]);
                        }}
                      />
                      <Edit />
                      <Typography variant="caption">Change photo</Typography>
                    </Button>
                  </Stack>

                  {profilePhoto !== null ? (
                    <img
                      src={profilePhoto}
                      title="profilepic"
                      alt="profilepic"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <Doty customClass={"animated tada"} pose="06" size="tiny" />
                  )}
                </Box>
                <Stack gap={1}>
                  <Typography variant={isMobile ? "h3" : "h4"}>
                    Hi, {user.name}!
                  </Typography>
                  <Streak />
                </Stack>
              </Stack>
              <Stack
                gap={1}
                padding={"1rem"}
                onMouseEnter={() => setUserSettingsHover(true)}
                onMouseLeave={() => setUserSettingsHover(false)}
                height={
                  userHover || userSettingsHover
                    ? user.profile === ADMIN_PROFILE
                      ? "10rem"
                      : "5rem"
                    : "0.001rem"
                }
                width={userHover || userSettingsHover ? "20rem" : "0"}
                position={"relative"}
                sx={{
                  transition: "all .4s",
                  opacity: userHover || userSettingsHover ? "1" : "0",
                }}
              >
                <Button variant="outlined" fullWidth onClick={logOutHandler}>
                  Log out
                </Button>
                {user.profile === ADMIN_PROFILE && (
                  <>
                    <Button
                      id="basic-button"
                      variant="outlined"
                      aria-controls={openAdmin ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openAdmin ? "true" : undefined}
                      onClick={handleClick}
                    >
                      Admin
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openAdmin}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => handleClose("/admin/levels")}
                        fullWidth
                      >
                        Levels
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/admin/readings")}
                        fullWidth
                      >
                        Readings
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/admin/users")}
                        fullWidth
                      >
                        Users
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Stack>
            </Stack>

            <Box width={"100%"} position={"relative"} fontSize={"2rem"}>
              <BottomNavigation
                sx={{ flexWrap: "wrap", height: "12rem" }}
                variant="contained"
                showLabels
                value={currentSection}
                onChange={(event, newValue) => {
                  setCurrentSection(newValue);
                }}
              >
                {menus.map((menu) => {
                  return (
                    <BottomNavigationAction
                      sx={{ minWidth: "33%", padding: "1rem" }}
                      label={menu.title}
                      icon={menu.icon}
                    />
                  );
                })}
              </BottomNavigation>
            </Box>

            <Stack
              height={isMobile ? "80rem" : "40rem"}
              position={"relative"}
              direction={"row"}
              justifyContent={"center"}
              sx={{
                transition: "all .8s ease",
                transform: `translateX(${
                  position - currentSection * position + marginPosition
                }rem)`,
              }}
            >
              <DailyProgress />
              {coming_soon}
              <ReadingsList />
              <TopStudents />
              <StreakTop />
              <GamesList />
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Dialog {...dialog} />
    </>
  );
}
