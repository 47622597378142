import React, { useEffect, useState } from "react";
import Doty from "../../../components/UI/Doty/Doty";
import { Box, Card, Stack, Typography, useMediaQuery } from "@mui/material";
import { getGamesService } from "../../../services/games.service";
import Bomb from "../../../assets/images/DotBombs/bomb.png";
import Baloon from "../../../assets/images/PopIt/balloon.png";
import { useTheme } from "@emotion/react";

export default function GamesList() {
  const [games, setGames] = useState([]);
  const [hover, setHover] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    const getGames = async () => {
      const response = await getGamesService();
      console.log("games", response);
      if (response.length > 0) {
        setGames(response);
      }
    };
    getGames();
  }, []);

  return (
    <Card
      sx={{
        gap: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem",
        width: isMobile ? "50rem" : "30rem",

        position: "relative",
        resize: "none",
        height: "100%",
       
        overflow:"hidden auto"
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <Doty pose="12" size={isMobile ? "tiny" : "mini"} />
        <Typography variant={isMobile ? "h3" : "h5"}>Let's play!</Typography>
      </Stack>
      <Stack gap={1}>
        {games.map((item) => {
          return (
            <Stack
              direction={"row"}
              onMouseEnter={() => setHover(item.id)}
              onMouseLeave={() => setHover(null)}
              key={item.id}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              onClick={() => {
                window.location.replace(`/games${item.path}`);
              }}
              sx={{
                backgroundColor: "#e6e6fc",
                padding: "1rem",
                borderRadius: "1rem",
                scale: hover === item.id ? "1.1" : "1",
                transition: "all .2s ease",
                cursor: "pointer",
                gap: 1,
              }}
            >
              {item.path === "/dont-pop" && (
                <img
                  src={Baloon}
                  title="bomb"
                  alt="bomb"
                  style={{
                    width: isMobile ? "6rem" : "4rem",
                  }}
                />
              )}

              {item.path === "/dot-bombs" && (
                <img
                  src={Bomb}
                  title="bomb"
                  alt="bomb"
                  style={{
                    width: isMobile ? "7rem" : "5rem",
                    transform: "translateX(-.5rem)",
                  }}
                />
              )}
              <Typography variant={isMobile ? "h4" : "h6"}>{item.name}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
}
