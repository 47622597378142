import {
  Box,
  Button,
  Container,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAllUsersService } from "../../../services/user.service";
import { DataGrid } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import Doty from "../../../components/UI/Doty/Doty";
import moment from "moment/moment";
import { BASE_URL_IMAGES } from "../../../constants";
import AdminEditUser from "./AdminEditUser";
const userToken = JSON.parse(localStorage.getItem("user"));

export default function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  useEffect(() => {
    const getUsers = async () => {
      const users_response = await getAllUsersService();
      if (users_response.length > 0) {
        setUsers(users_response);
      }
    };
    getUsers();
  }, []);
  const enableUserHandler = (user) => {
    console.log(user);
  };
  const editUserHandler = (user) => {
    console.log('useeeer', user);
    setSelectedUser(user);
    setShow(true);
  };
  const sendEditUser = (user) => {
    console.log("user", user);
  };

  const columns = [
    {
      field: "prodile_pic",
      sortable: true,
      disableColumnMenu: true,
      headerName: "Profile",
      renderCell: ({ row }) => {
        const image =
          row.profile_pic !== "" && row.profile_pic != null ? (
            <img
              style={{ width: "4rem" }}
              key={row.id}
              src={`${BASE_URL_IMAGES}/users/${row.profile_pic}`}
              alt="profile pc"
            />
          ) : (
            <Doty pose={"02"} size="mini" />
          );
        return image;
      },
    },
    {
      field: "name",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Name",
      width: 130,
      renderCell: ({ row }) => {
        return <Typography variant="body1">{row.name}</Typography>;
      },
    },
    {
      field: "last_name",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Lastname",
      width: 130,
      renderCell: ({ row }) => {
        return <Typography variant="body1">{row.last_name}</Typography>;
      },
    },
    {
      field: "username",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Username",
      width: 130,
      renderCell: ({ row }) => {
        return <Typography variant="body1">{row.username}</Typography>;
      },
    },
    {
      field: "birth",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Birthday",
      width: 130,
      renderCell: ({ row }) => {
        return (
          <Typography variant="body1">
            {moment(row.birth).format("MM-DD-YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "creationDate",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Member since",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Typography variant="body1">
            {moment(row.creationDate).format("MM-DD-YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "expires",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Expires",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Typography variant="body1">
            {row.expires !== null
              ? moment(row.expires).format("MM-DD-YYYY")
              : "No"}
          </Typography>
        );
      },
    },
    {
      field: "blocked",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Enabled",

      renderCell: ({ row }) => {
        return (
          <Stack justifyContent="center" width="100%">
            <Button
              onClick={() => {
                enableUserHandler(row);
              }}
            >
              {row.blocked === 0 ? <Visibility /> : <VisibilityOff />}
            </Button>
          </Stack>
        );
      },
    },
    {
      headerName: "Edit",
      sortable: false,
      disableColumnMenu: true,

      renderCell: ({ row }) => {
        return (
          <Box
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              onClick={() => {
                editUserHandler(row);
              }}
            >
              <Edit />
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Container>
        <TextField
          variant="outlined"
          label="Search"
          multiline
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Box sx={{ height: "60vh" }}>
          <DataGrid
            rows={
              search !== ""
                ? users.filter(
                    (user) =>
                      user.name.toUpperCase().includes(search.toUpperCase()) ||
                      user.last_name
                        .toUpperCase()
                        .includes(search.toUpperCase())
                  )
                : users
            }
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection={false}
            disableRowSelectionOnClick
            autoPageSize
          />
        </Box>
      </Container>
      <AdminEditUser
        show={show}
        user={selectedUser}
        cancel={() => setShow(false)}
        sendEditUser={() => {
          sendEditUser();
        }}
      />
    </>
  );
}
