import React, { useEffect, useState } from "react";
import axios from "axios";
import md5 from "md5";

import Input from "../UI/Input/Input";

import Doty from "../UI/Doty/Doty";
import Spinner from "../UI/Spinner/Spinner";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as moment from "moment";

import Logo from "../../assets/images/logos/DOTS_LOGO_DARK.png";
import background from "../../assets/background.png";

import "./Login.scss";
import { Card, Stack, Typography, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { loginService } from "../../services/user.service";

export default function Login() {
  const [handler, setHandler] = useState(null);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [login, setLogin] = useState("login");
  const [incorrect, setIncorrect] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState(dayjs("2022-04-17"));
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [msg, setMsg] = useState("");
  const [code, setCode] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (password.split("").length > 4) {
          loginHandler();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  const loginHandler = async () => {
    let data = {
      username: user,
      password: md5(password),
    };
    const response = await loginService(data);
    console.log("response", response);

    if (response.status === 200) {
      let date = new Date();
      console.log(response.data);
      const user_store = {
        lastLog: date,
        auth: true,
        id: response.data.id_user,
        ...response.data,
      };

      console.log(user_store);
      localStorage.setItem("user", JSON.stringify(user_store));
      localStorage.setItem("profile", response.data.profile);
      localStorage.setItem("streak", response.data.streak);

      window.location.replace("/levels");
    } else {
      setIncorrect(true);
      setMsg(response.data.error);
      setTimeout(() => {
        setIncorrect(false);
      }, 10000);
    }
  };

  const signInHandler = () => {
    if (name === "") {
      setMsg("Please fill the Name input!");
    } else if (lastName === "") {
      setMsg("Please fill the Last name input!");
    } else if (birthday === "") {
      setMsg("Please fill the Birthday input!");
    } else if (birthday === "") {
      setMsg("Please fill the Birthday input!");
    } else if (email !== email2) {
      setMsg("Emails must match!");
    } else if (password.length < 8) {
      setMsg("Your password must be 8 or more characters long!");
    } else if (password !== password2) {
      setMsg("Both passwords must be the same!");
    } else {
      setMsg("");
      setLogin("loading");

      sendNewUser();
    }
  };

  const newUserHandler = (clean) => {
    setLogin("new-user");
    if (clean) {
      setName("");
      setLastName("");
      setBirthday("");
      setBirthday("");
      setEmail("");
      setMsg("");
    }
  };

  const sendNewUser = () => {
    let data = {
      name: name,
      lastName: lastName,
      email: email,
      password: md5(password2),
      birth: moment(birthday).format("YYYY-MM-DD"),
      code: code,
    };
    axios
      .post("/newUser", data)
      .then((response) => {
        if (response.data.result === "OK") {
          setLogin("username");
          setNewUsername(response.data.username);
        } else if (response.data.result === "NOK5") {
          setLogin("wrongcode");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setLogin("error");
        setErrorMessage(error.response.data.error);
      });
  };

  let content = null;
  if (login === "login") {
    content = (
      <Card
        sx={{
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          transition: "all .2s",
        }}
      >
        <div className="Login__container">
          <img className="Login__img animate" src={Logo} alt="DOTS" />
        </div>
        {incorrect ? <span className="Login__incorrect">{msg}</span> : ""}
        <Stack width={"100%"} gap={1}>
          <Input
            fullWidth
            value={user}
            change={(event) => {
              setUser(event.target.value);
            }}
            placeHolder="User"
            type="text"
          />
          <Input
            fullWidth
            value={password}
            change={(event) => {
              setPassword(event.target.value);
            }}
            placeHolder="Password"
            type="password"
          />
          <Button
            size="large"
            fullWidth
            variant="contained"
            margin="dense"
            onClick={loginHandler}
          >
            Log in
          </Button>
          <Button
            size="large"
            fullWidth
            variant="outlined"
            onClick={() => {
              console.log("CLICKS");
              window.location.replace("/forgot");
            }}
          >
            forgot password?
          </Button>
          <Button
            size="large"
            fullWidth
            variant="outlined"
            onClick={() => {
              newUserHandler(true);
            }}
          >
            New in dots? click here!
          </Button>
        </Stack>
      </Card>
    );
  } else if (login === "new-user") {
    content = (
      <Card sx={{ padding: "2rem" }}>
        <Typography variant="h1">Welcome!</Typography>
        <Typography variant="h6">Please fill the form to sign in!</Typography>
        <Typography
          textAlign={"center"}
          variant="h6"
          className="Login__new-user-msg-error"
        >
          {msg}
        </Typography>
        <div className="Login__content" style={{ marginBottom: "2rem" }}>
          <Input
            value={name}
            change={(event) => {
              setName(event.target.value);
            }}
            placeHolder="Name"
            type="text"
            customClass="Input__medium"
            noComplete
            fullWidth
          />
          <Input
            value={lastName}
            change={(event) => {
              setLastName(event.target.value);
            }}
            placeHolder="Last name"
            type="text"
            customClass="Input__medium"
            noComplete
            fullWidth
          />
          <Input
            value={email}
            change={(event) => {
              setEmail(event.target.value);
            }}
            placeHolder="Email"
            type="email"
            customClass="Input__medium"
            noComplete
            fullWidth
          />
          <Input
            value={email2}
            change={(event) => {
              setEmail2(event.target.value);
            }}
            placeHolder="Confirm email"
            type="email"
            customClass="Input__medium"
            noComplete
            fullWidth
          />
          <Input
            value={password}
            change={(event) => {
              setPassword(event.target.value);
            }}
            placeHolder="Password"
            type="password"
            customClass="Input__medium"
            noComplete
            fullWidth
          />
          <Input
            value={password2}
            change={(event) => {
              setPassword2(event.target.value);
            }}
            placeHolder="Confirm password"
            type="password"
            customClass="Input__medium"
            noComplete
            fullWidth
          />

          {/*}
          <Input
            value={birthday}
            change={(event) => {
              setBirthday(event.target.value);
            }}
            placeHolder="Birthdate"
            type="date"
            fullWidth
          />
          */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Birthdate"
                onChange={(newValue) => setBirthday(newValue)}
                fullWidth
              />
            </DemoContainer>
          </LocalizationProvider>
          {/*<Input
            value={code}
            change={(event) => {
              setCode(event.target.value);
            }}
            placeHolder="Code"
            type="text"
            customClass="Input__medium"
            noComplete
            fullWidth
          />
          */}
        </div>
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <Button
            style={{ width: "25%" }}
            variant={"outlined"}
            margin={" auto 0"}
            onClick={() => {
              setLogin("login");
            }}
          >
            go back
          </Button>
          <Button
            style={{ width: "25%" }}
            variant={"contained"}
            margin={" auto 0"}
            onClick={signInHandler}
          >
            Sign in
          </Button>
        </Stack>
      </Card>
    );
  } else if (login === "loading") {
    content = (
      <Card sx={{ padding: "2rem" }}>
        <Typography variant="h2">Please wait!</Typography>
        <Spinner color="Loader" />
      </Card>
    );
  } else if (login === "username") {
    content = (
      <Card
        sx={{ padding: "2rem", width: "50rem" }}
        className="Login__user-created"
      >
        <Doty pose="02" size="small" />
        <Typography variant="h2" gutterBottom>
          Alright!
        </Typography>
        <Typography variant="h5" gutterBottom>
          We have sent an email to: {email} <br /> notifying your user!
        </Typography>
        <Button
          variant={"contained"}
          onClick={() => {
            setLogin("login");
          }}
        >
          go and log in!
        </Button>
      </Card>
    );
  } else if (login === "error") {
    content = (
      <Card sx={{ padding: "2rem" }}>
        <Stack alignItems={"center"} justifyContent={"center"} gap={2}>
          <Doty pose="05" size="small" />
          <Typography variant="h2">Oops!</Typography>
          <Typography variant="h6">{errorMessage}</Typography>

          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              newUserHandler(false);
            }}
          >
            retry
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              setLogin("login");
            }}
          >
            go to login
          </Button>
        </Stack>
      </Card>
    );
  }
  return (
    <div className="Login" style={{ backgroundImage: `url(${background})` }}>
      {content}
      {handler}
    </div>
  );
}
