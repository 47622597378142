import React, { PureComponent } from 'react';

import WordImg from '../../../../components/UI/WordImg/WordImg';


import bomb_img from '../../../../assets/images/DotBombs/bomb.png';
import bomb_explode from '../../../../assets/images/DotBombs/bomb-explosion.png';
import correct from '../../../../assets/images/answers/checkmark.png';

import './Bomb.scss';

class Bomb extends PureComponent {
  state ={
    distance:null,
    title:null,
    src:null
  }

  componentDidMount (){
    this.setState({distance:this.props.distance,title:this.props.title,src:this.props.src})
  }



  componentDidUpdate(nextProps,nextState){
    this.setState({distance:this.props.distance,title:this.props.title,src:this.props.src})
  }
  render(){
    let bomb = null

    if(this.state.distance >= this.props.fallDistance){
      bomb = <img className="Bomb__img  Bomb__explode animated tada" src={bomb_explode} alt=""/>
    }else{
      if(this.state.src){
        if(this.state.src === 'bomb' || this.state.src === ''){
          bomb = <img className="Bomb__img" src={bomb_img} alt=""/>
        }
        if(this.state.src === 'correct'){
          bomb = <img className="Bomb__img animated tada" src={correct} alt=""/>
        }
        if(this.state.src !== 'correct' && this.state.src !== 'bomb' && this.state.src !== ''){
          bomb = <WordImg size="small" src={this.state.src} />
        }
      }
    }


    return (
      <div className="Bomb" style={{marginTop:this.state.distance + "%"}} >
        {bomb}
        {this.state.title}
        </div>
    );
  }
}

export default Bomb;
