import React, { useEffect, useState } from "react";
import Doty from "../../../components/UI/Doty/Doty";
import { Box, Card, Stack, Typography, useMediaQuery } from "@mui/material";
import { getEnabledReadingsService } from "../../../services/reading.service";
import Lock from "../../../assets/images/Lock_icon.png";
import { useTheme } from "@emotion/react";

export default function ReadingsList() {
  const [readings, setReadings] = useState([]);
  const [hover, setHover] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    const getReadings = async () => {
      const response = await getEnabledReadingsService(user);
      console.log("readings", response);
      if (response.length > 0) {
        setReadings(response);
      }
    };
    getReadings();
  }, []);

  return (
    <Card
      sx={{
        gap: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem",
        width:  isMobile ? "50rem" : "30rem",
        boxShadow: "",
        position: "relative",
        height: "100%",
       
        overflow:"hidden auto"
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <Doty pose="17" size={isMobile ? "tiny" : "mini"}/>
        <Typography variant={isMobile ? "h3" : "h5"}>Let's read!</Typography>
      </Stack>
      <Stack gap={1}>
        {readings.map((item) => {
          return (
            <Stack
              onMouseEnter={() => setHover(item.id)}
              onMouseLeave={() => setHover(null)}
              key={item.id}
              direction={"row"}
              alignItems={"center"}
              gap={2}
              onClick={() => {
                item.unlocked &&
                  window.location.replace(`/readings/${item.id}`);
              }}
              sx={{
                backgroundColor: "#e6e6fc",
                padding: isMobile ? "2rem": "1rem",
                borderRadius: "1rem",
                scale: hover === item.id ? "1.1" : "1",
                transition: "all .2s ease",
                cursor: "pointer",
              }}
            >
              {!item.unlocked && (
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <img src={Lock} alt="Locked" style={{ width: "2rem" }} />
                  <Typography variant="subtitle2">
                    {item.unlock_left}
                  </Typography>
                </Stack>
              )}
              <Typography variant={isMobile ? "h4": "h6"}>{item.title}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
}
