import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import {
  readingByIdService,
  updateReadingService,
} from "../../../services/reading.service";
import { useSearchParams } from "react-router-dom";
import Dialog from "../../../components/UI/Dialog/Dialog";
import AdminReadingsEditor from "./AdminReadingsEditor";

export default function AdminReadingsEdit() {
  const [reading, setReading] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialog, setDialog] = useState({});

  const id_reading = searchParams.get("id") ? searchParams.get("id") : 0;

  useEffect(() => {
    const getReadingById = async () => {
      const reading_item = await readingByIdService(id_reading);
      if (reading_item) {
        setReading(reading_item);
      }
    };

    getReadingById();
  }, [id_reading]);
  const closeDialogHandler = () => {
    setDialog({ show: false });
  };
  const saveReading = (title, text, readingAudio) => {
    console.log(title, text, readingAudio);
    const reading_service = updateReadingService(
      id_reading,
      text,
      title,
      readingAudio
    );
    console.log(reading_service);
    if (reading_service) {
      setDialog({
        text: "Reading updated!",
        type: "success",
        accept: () => {
          window.location.reload();
        },
        accept_text: "Great!",
        show: true,
      });
    } else {
      setDialog({
        text: "An error ocurred while updating this reading",
        type: "error",
        accept: () => {
          closeDialogHandler();
        },
        accept_text: "Ok",
        show: true,
      });
    }
  };

  if (id_reading === 0) {
    return;
  }

  return reading ? (
    <>
      <AdminReadingsEditor reading={reading} saveReading={saveReading} />
      <Dialog {...dialog} />
    </>
  ) : (
    <Box>Loading</Box>
  );
}
