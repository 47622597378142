import { useState, useEffect} from 'react';
import { useSearchParams } from 'react-router-dom'
import axios from 'axios';

import Spinner from '../../components/UI/Spinner/Spinner';
import Bubble from '../../components/UI/Bubble/Bubble';
import H from '../../components/UI/H/H';
import WordImg from '../../components/UI/WordImg/WordImg';
import GoBack from '../../components/UI/GoBack/GoBack';
import Sound from '../../hoc/Sound/Sound';

import './Spelling.scss';
import { Container } from '@mui/material';

const Spelling = () => {
  const [spelling, setSpelling] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id') ? searchParams.get('id') : 0;

  useEffect( () => {

    axios.get("/word/get-by-level/" + id)
    .then(response => {
      setSpelling(response.data)
    })
    .catch(error => {
      console.log(error);
    })
  }, [id] )
    let words =<Spinner />

    if(spelling){
      words = spelling.map(word => {
        return (
          <Sound key={word.id} src={word.audio} icon>
            <Bubble  size="small" title={word.text}>
              <WordImg size="Spelling__img" src={word.img} />
            </Bubble>
          </Sound>
          );
      })
    }
    return(
      <Container className="Spelling">
        <GoBack to={"/levels"} />
        <H className="u-margin-bot-medium" type="1">Spelling</H>
        <div className="Spelling__container">
          {words}
        </div>

      </Container>
    );
  
}

export default Spelling;
